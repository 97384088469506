import types from "./types";
import { ApiService } from "../../../utils";

const PATH = "RecreationCenter";

/*FETCH CENTER*/

export const fetchCenters = (center = {}) => async (dispatch) => {
  dispatch(fetchCentersRequest());
  try {
    const request = await ApiService.apiCall(`${PATH}/GetAllRecreationCenters?pageNumber=0&pageSize=100&isDetailed=true`, center, false, 'GET');
    if (request.statusCode === 200) {
      dispatch(fetchCentersSuccess(request));
    } else {
      dispatch(fetchCentersError(request.err));
    }
  } catch (err) {
    dispatch(fetchCentersError(err));
  }
};

const fetchCentersRequest = () => {
  return {
    type: types.FETCH_CENTERS_REQUEST,
  };
};

const fetchCentersSuccess = (data) => {
  return {
    type: types.FETCH_CENTERS_SUCCESS,
    payload: data.responseObject,
  };
};

const fetchCentersError = (error) => {
  return {
    type: types.FETCH_CENTERS_ERROR,
    payload: error.message,
    status: error.status,
  };
};

/*FETCH SINGLE CENTER*/

export const fetchCenter = (centerId = {}) => async (dispatch) => {
  dispatch(fetchCenterRequest());
  try {
    const request = await ApiService.apiCall(`${PATH}/GetRecreationCenter/${centerId}?isDetailed=true`, {}, false, 'GET');
    if (request.statusCode === 200) {
      dispatch(fetchCenterSuccess(request));
    } else {
      dispatch(fetchCenterError(request.err));
    }
  } catch (err) {
    dispatch(fetchCenterError(err));
  }
};

const fetchCenterRequest = () => {
  return {
    type: types.FETCH_CENTER_REQUEST,
  };
};

const fetchCenterSuccess = (data) => {
  return {
    type: types.FETCH_CENTER_SUCCESS,
    payload: data.responseObject,
  };
};

const fetchCenterError = (error) => {
  return {
    type: types.FETCH_CENTER_ERROR,
    payload: error.message,
    status: error.status,
  };
};

/*ADD CENTER*/

export const addCenter = (center = {}) => async (dispatch) => {
  
  dispatch(AddCenterRequest());
  try {
    const request = await ApiService.apiCall(`${PATH}/CreateRecreationCenter`, center);
    if (request.statusCode === 200) {
      dispatch(AddCenterSuccess(request));
    } else {
      dispatch(AddCenterError(request.err));
    }
  } catch (err) {
    dispatch(AddCenterError(err));
  }
};

const AddCenterRequest = () => {
  return {
    type: types.ADD_CENTER_REQUEST,
  };
};

const AddCenterSuccess = (data) => {
  return {
    type: types.ADD_CENTER_SUCCESS,
    payload: data.message,
  };
};

const AddCenterError = (error) => {
  return {
    type: types.ADD_CENTER_ERROR,
    payload: error.message,
    status: error.status,
  };
};


/*UPDATE CENTER*/

export const updateCenter = (centerData = {}, centerId=null) => async (dispatch) => {
  dispatch(updateCenterRequest());
  try {
    const request = await ApiService.apiCall(`${PATH}/UpdateRecreationCenter/${centerId}`, centerData, false, 'PUT');
    if (request.statusCode === 200) {
      dispatch(updateCenterSuccess(request));
    } else {
      dispatch(updateCenterError(request.err));
    }
  } catch (err) {
    dispatch(updateCenterError(err));
  }
};

const updateCenterRequest = () => {
  return {
    type: types.UPDATE_CENTER_REQUEST,
  };
};

const updateCenterSuccess = (data) => {
  return {
    type: types.UPDATE_CENTER_SUCCESS,
    payload: data.message,
  };
};

const updateCenterError = (error) => {
  return {
    type: types.UPDATE_CENTER_ERROR,
    payload: error.message,
    status: error.status,
  };
};

/* DELETE CENTER*/

export const deleteCenter = (centerId = null) => async (dispatch) => {
  dispatch(deleteCenterRequest());

  try {
    const request = await ApiService.apiCall(
      `${PATH}/DeleteRecreationCenter/${centerId}`,
      {},
      false,
      "DELETE"
    );
    if (request.statusCode === 200) {
      dispatch(deleteCenterSuccess(request));
    } else {
      dispatch(deleteCenterError(request.err));
    }
  } catch (err) {
    dispatch(deleteCenterError(err));
  }
};

const deleteCenterRequest = () => {
  return {
    type: types.DELETE_CENTER_REQUEST,
  };
};

const deleteCenterSuccess = (data) => {
  return {
    type: types.DELETE_CENTER_SUCCESS,
    payload: data.message,
  };
};

const deleteCenterError = (error) => {
  return {
    type: types.DELETE_CENTER_ERROR,
    payload: error.message,
    status: error.status,
  };
};

const deleteCenterErrorReset = () => {
  return {
    type: types.DELETE_CENTER_ERROR_RESET,
  };
};


export function reset() {
    return {
      type: types.RESET,
    };
  }

export default {
  deleteCenter,
  updateCenter,
  addCenter,
  fetchCenters,
  fetchCenter,
  reset,
  deleteCenterErrorReset
};
