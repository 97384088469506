import types from "./types";
import { ApiService } from "../../../utils";

const ROL_PATH = "Rol";

/*FETCH ROLES*/

export const fetchUserRoles = (idUser = null) => async (dispatch) => {
  dispatch(fetchUserRolesRequest());
  try {
    const request = await ApiService.apiCall(`${ROL_PATH}/GetAllRolFromUser/${idUser}`, {}, false, 'GET');
    if (request.statusCode === 200) {
      dispatch(fetchUserRolesSuccess(request));
    } else {
      dispatch(fetchUserRolesError(request.err));
    }
  } catch (err) {
    dispatch(fetchUserRolesError(err));
  }
};

const fetchUserRolesRequest = () => {
  return {
    type: types.FETCH_USER_ROLES_REQUEST,
  };
};

const fetchUserRolesSuccess = (data) => {
  return {
    type: types.FETCH_USER_ROLES_SUCCESS,
    payload: data.responseObject,
  };
};

const fetchUserRolesError = (error) => {
  return {
    type: types.FETCH_USER_ROLES_ERROR,
    payload: error.message,
    status: error.status,
  };
};
 
/*FETCH SINGLE ROLE*/

export const fetchUserRole = (roleId = {}) => async (dispatch) => {
  dispatch(fetchUserRoleRequest());
  try {
    const request = await ApiService.apiCall(`${ROL_PATH}/GetRol/${roleId}?isDetailed=true`, {}, false, 'GET');
    if (request.statusCode === 200) {
      dispatch(fetchUserRoleSuccess(request));
    } else {
      dispatch(fetchUserRoleError(request.err));
    }
  } catch (err) {
    dispatch(fetchUserRoleError(err));
  }
};

const fetchUserRoleRequest = () => {
  return {
    type: types.FETCH_USER_ROLE_REQUEST,
  };
};

const fetchUserRoleSuccess = (data) => {
  return {
    type: types.FETCH_USER_ROLE_SUCCESS,
    payload: data.responseObject,
  };
};

const fetchUserRoleError = (error) => {
  return {
    type: types.FETCH_USER_ROLE_ERROR,
    payload: error.message,
    status: error.status,
  };
};

/*ADD ROLE*/

export const addUserRole = (role = {}) => async (dispatch) => {
  
  dispatch(AddUserRoleRequest());
  try {
    const request = await ApiService.apiCall(`${ROL_PATH}/CreateRolFromUser`, role);
    if (request.statusCode === 200) {
      dispatch(AddUserRoleSuccess(request));
    } else {
      dispatch(AddUserRoleError(request.err));
    }
  } catch (err) {
    dispatch(AddUserRoleError(err));
  }
};

const AddUserRoleRequest = () => {
  return {
    type: types.ADD_USER_ROLE_REQUEST,
  };
};

const AddUserRoleSuccess = (data) => {
  return {
    type: types.ADD_USER_ROLE_SUCCESS,
    payload: data.message,
  };
};

const AddUserRoleError = (error) => {
  return {
    type: types.ADD_USER_ROLE_ERROR,
    payload: error.message,
    status: error.status,
  };
};


/*UPDATE ROLE*/

export const updateUserRole = (idUser = null, rol={}) => async (dispatch) => {
  dispatch(updateUserRoleRequest());
  try {
    const request = await ApiService.apiCall(`${ROL_PATH}/UpdateRolFromUser/${idUser}`, rol, false, 'PUT');
    if (request.statusCode === 200) {
      dispatch(updateUserRoleSuccess(request));
    } else {
      dispatch(updateUserRoleError(request.err));
    }
  } catch (err) {
    dispatch(updateUserRoleError(err));
  }
};

const updateUserRoleRequest = () => {
  return {
    type: types.UPDATE_USER_ROLE_REQUEST,
  };
};

const updateUserRoleSuccess = (data) => {
  return {
    type: types.UPDATE_USER_ROLE_SUCCESS,
    payload: data.message,
  };
};

const updateUserRoleError = (error) => {
  return {
    type: types.UPDATE_USER_ROLE_ERROR,
    payload: error.message,
    status: error.status,
  };
};

/* DELETE ROL*/

export const deleteUserRole = (idRol = null) => async (dispatch) => {
  dispatch(deleteUserRoleRequest());

  try {
    const request = await ApiService.apiCall(
      `${ROL_PATH}/DeleteRolFromUser/${idRol}`,
      {},
      false,
      "DELETE"
    );
    if (request.statusCode === 200) {
      dispatch(deleteUserRoleSuccess(request));
    } else {
      dispatch(deleteUserRoleError(request.err));
    }
  } catch (err) {
    dispatch(deleteUserRoleError(err));
  }
};

const deleteUserRoleRequest = () => {
  return {
    type: types.DELETE_USER_ROLE_REQUEST,
  };
};

const deleteUserRoleSuccess = (data) => {
  return {
    type: types.DELETE_USER_ROLE_SUCCESS,
    payload: data.message,
  };
};

const deleteUserRoleError = (error) => {
  return {
    type: types.DELETE_USER_ROLE_ERROR,
    payload: error.message,
    status: error.status,
  };
};

export function reset() {
  return {
    type: types.RESET,
  };
}


export default {
  fetchUserRoles,
  fetchUserRole,
  addUserRole,
  updateUserRole,
  deleteUserRole,
  reset,
};
