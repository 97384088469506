import types from "./types";
import { ApiService } from "../../../utils";

const PATH = "HighSeason";

/*FETCH SEASON*/

export const fetchSeasons = (season = {}) => async (dispatch) => {
  dispatch(fetchSeasonsRequest());
  try {
    const request = await ApiService.apiCall(`${PATH}/GetAllHighSeasons?pageNumber=0&pageSize=100&isDetailed=true`, season, false, 'GET');
    if (request.statusCode === 200) {
      dispatch(fetchSeasonsSuccess(request));
    } else {
      dispatch(fetchSeasonsError(request.err));
    }
  } catch (err) {
    dispatch(fetchSeasonsError(err));
  }
};

const fetchSeasonsRequest = () => {
  return {
    type: types.FETCH_SEASONS_REQUEST,
  };
};

const fetchSeasonsSuccess = (data) => {
  return {
    type: types.FETCH_SEASONS_SUCCESS,
    payload: data.responseObject,
  };
};

const fetchSeasonsError = (error) => {
  return {
    type: types.FETCH_SEASONS_ERROR,
    payload: error.message,
    status: error.status,
  };
};

/*FETCH SINGLE SEASON*/

export const fetchSeason = (seasonId = null) => async (dispatch) => {
  dispatch(fetchSeasonRequest());
  try {
    const request = await ApiService.apiCall(`${PATH}/GetHighSeason/${seasonId}`, {}, false, 'GET');
    if (request.statusCode === 200) {
      dispatch(fetchSeasonSuccess(request));
    } else {
      dispatch(fetchSeasonError(request.err));
    }
  } catch (err) {
    dispatch(fetchSeasonError(err));
  }
};

const fetchSeasonRequest = () => {
  return {
    type: types.FETCH_SEASON_REQUEST,
  };
};

const fetchSeasonSuccess = (data) => {
  return {
    type: types.FETCH_SEASON_SUCCESS,
    payload: data.responseObject,
  };
};

const fetchSeasonError = (error) => {
  return {
    type: types.FETCH_SEASON_ERROR,
    payload: error.message,
    status: error.status,
  };
};

/*ADD SEASON*/

export const addSeason = (season = {}) => async (dispatch) => {
  
  dispatch(AddSeasonRequest());
  try {
    const request = await ApiService.apiCall(`${PATH}/CreateHighSeason`, season);
    if (request.statusCode === 200) {
      dispatch(AddSeasonSuccess(request));
    } else {
      dispatch(AddSeasonError(request.err));
    }
  } catch (err) {
    dispatch(AddSeasonError(err));
  }
};

const AddSeasonRequest = () => {
  return {
    type: types.ADD_SEASON_REQUEST,
  };
};

const AddSeasonSuccess = (data) => {
  return {
    type: types.ADD_SEASON_SUCCESS,
    payload: data.message,
  };
};

const AddSeasonError = (error) => {
  return {
    type: types.ADD_SEASON_ERROR,
    payload: error.message,
    status: error.status,
  };
};


/*UPDATE SEASON*/

export const updateSeason = (seasonData = {}, seasonId=null) => async (dispatch) => {
  dispatch(updateSeasonRequest());
  try {
    const request = await ApiService.apiCall(`${PATH}/UpdateHighSeason/${seasonId}`, seasonData, false, 'PUT');
    if (request.statusCode === 200) {
      dispatch(updateSeasonSuccess(request));
    } else {
      dispatch(updateSeasonError(request.err));
    }
  } catch (err) {
    dispatch(updateSeasonError(err));
  }
};

const updateSeasonRequest = () => {
  return {
    type: types.UPDATE_SEASON_REQUEST,
  };
};

const updateSeasonSuccess = (data) => {
  return {
    type: types.UPDATE_SEASON_SUCCESS,
    payload: data.message,
  };
};

const updateSeasonError = (error) => {
  return {
    type: types.UPDATE_SEASON_ERROR,
    payload: error.message,
    status: error.status,
  };
};

/* DELETE SEASON*/

export const deleteSeason = (seasonId = null) => async (dispatch) => {
  dispatch(deleteSeasonRequest());

  try {
    const request = await ApiService.apiCall(
      `${PATH}/DeleteHighSeason/${seasonId}`,
      {},
      false,
      "DELETE"
    );
    if (request.statusCode === 200) {
      dispatch(deleteSeasonSuccess(request));
    } else {
      dispatch(deleteSeasonError(request.err));
    }
  } catch (err) {
    dispatch(deleteSeasonError(err));
  }
};

const deleteSeasonRequest = () => {
  return {
    type: types.DELETE_SEASON_REQUEST,
  };
};

const deleteSeasonSuccess = (data) => {
  return {
    type: types.DELETE_SEASON_SUCCESS,
    payload: data.message,
  };
};

const deleteSeasonError = (error) => {
  return {
    type: types.DELETE_SEASON_ERROR,
    payload: error.message,
    status: error.status,
  };
};


export function reset() {
    return {
      type: types.RESET,
    };
  }


export function resetSingleSeason() {
  return {
    type: types.RESET_SINGLE_SEASON,
  };
}

export default {
  deleteSeason,
  updateSeason,
  addSeason,
  fetchSeasons,
  fetchSeason,
  reset,
  resetSingleSeason
};
