const ADD_DATE_REQUEST = "ADD_DATE_REQUEST";
const ADD_DATE_SUCCESS = "ADD_DATE_SUCCESS";
const ADD_DATE_ERROR = "ADD_DATE_ERROR";

const DELETE_DATE_REQUEST = "DELETE_DATE_REQUEST";
const DELETE_DATE_SUCCESS = "DELETE_DATE_SUCCESS";
const DELETE_DATE_ERROR = "DELETE_DATE_ERROR";

const FETCH_DATES_REQUEST = "FETCH_DATES_REQUEST";
const FETCH_DATES_SUCCESS = "FETCH_DATES_SUCCESS";
const FETCH_DATES_ERROR = "FETCH_DATES_ERROR";

const FETCH_DATE_REQUEST = "FETCH_DATE_REQUEST";
const FETCH_DATE_SUCCESS = "FETCH_DATE_SUCCESS";
const FETCH_DATE_ERROR = "FETCH_DATE_ERROR";

const UPDATE_DATE_REQUEST = "UPDATE_DATE_REQUEST";
const UPDATE_DATE_SUCCESS = "UPDATE_DATE_SUCCESS";
const UPDATE_DATE_ERROR = "UPDATE_DATE_ERROR";

const RESET = "RESET";
const RESET_SINGLE = "RESET_SINGLE";


export default {
  ADD_DATE_REQUEST,
  ADD_DATE_SUCCESS,
  ADD_DATE_ERROR,
  DELETE_DATE_REQUEST,
  DELETE_DATE_SUCCESS,
  DELETE_DATE_ERROR,
  FETCH_DATES_REQUEST,
  FETCH_DATES_SUCCESS,
  FETCH_DATES_ERROR,
  UPDATE_DATE_REQUEST,
  UPDATE_DATE_SUCCESS,
  UPDATE_DATE_ERROR,
  FETCH_DATE_REQUEST,
  FETCH_DATE_SUCCESS,
  FETCH_DATE_ERROR,
  RESET,
  RESET_SINGLE,
};
