const ADD_CENTER_REQUEST = "ADD_CENTER_REQUEST";
const ADD_CENTER_SUCCESS = "ADD_CENTER_SUCCESS";
const ADD_CENTER_ERROR = "ADD_CENTER_ERROR";

const DELETE_CENTER_REQUEST = "DELETE_CENTER_REQUEST";
const DELETE_CENTER_SUCCESS = "DELETE_CENTER_SUCCESS";
const DELETE_CENTER_ERROR = "DELETE_CENTER_ERROR";
const DELETE_CENTER_ERROR_RESET = "DELETE_CENTER_ERROR_RESET";

const FETCH_CENTERS_REQUEST = "FETCH_CENTERS_REQUEST";
const FETCH_CENTERS_SUCCESS = "FETCH_CENTERS_SUCCESS";
const FETCH_CENTERS_ERROR = "FETCH_CENTERS_ERROR";

const FETCH_CENTER_REQUEST = "FETCH_CENTER_REQUEST";
const FETCH_CENTER_SUCCESS = "FETCH_CENTER_SUCCESS";
const FETCH_CENTER_ERROR = "FETCH_CENTER_ERROR";

const UPDATE_CENTER_REQUEST = "UPDATE_CENTER_REQUEST";
const UPDATE_CENTER_SUCCESS = "UPDATE_CENTER_SUCCESS";
const UPDATE_CENTER_ERROR = "UPDATE_CENTER_ERROR";

const RESET = "RESET";

export default {
 ADD_CENTER_REQUEST,
  ADD_CENTER_SUCCESS,
  ADD_CENTER_ERROR,
  DELETE_CENTER_REQUEST,
  DELETE_CENTER_SUCCESS,
  DELETE_CENTER_ERROR,
  FETCH_CENTERS_REQUEST,
  FETCH_CENTERS_SUCCESS,
  FETCH_CENTERS_ERROR,
  UPDATE_CENTER_REQUEST,
  UPDATE_CENTER_SUCCESS,
  UPDATE_CENTER_ERROR,
  FETCH_CENTER_REQUEST,
  FETCH_CENTER_SUCCESS,
  FETCH_CENTER_ERROR,
  RESET,
  DELETE_CENTER_ERROR_RESET
};
