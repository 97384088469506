import { combineReducers } from "redux";
import types from "./types";

const initialState = {
  isDeleting: false,
  isDeleteSuccess: false,
  isDeleteError: false,
  isAdding: false,
  isAddSuccess: false,
  isAddError: false,
  isUpdating: false,
  isUpdateSuccess: false,
  isUpdateError: false,
  isFetching: false,
  isFetchSuccess: false,
  isFetchError: false,
  userRolesData: [],
  userRoleData: null
};

export function userRole(state = initialState, action) {
  switch (action.type) {
    case types.ADD_USER_ROLE_REQUEST:
      return {
        ...state,
        isAdding: true,
      };
    case types.ADD_USER_ROLE_SUCCESS:
      return {
        ...state,
        isAdding: false,
        isAddSuccess: true,
        isAddError: false,
      };
    case types.ADD_USER_ROLE_ERROR:
      return {
        ...state,
        isAdding: false,
        isAddSuccess: false,
        isAddError: true,
      };
    case types.UPDATE_USER_ROLE_REQUEST:
      return {
        ...state,
        isUpdating: true,
      };
    case types.UPDATE_USER_ROLE_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        isUpdateSuccess: true,
        isUpdateError: false,
      };
    case types.UPDATE_USER_ROLE_ERROR:
      return {
        ...state,
        isUpdating: false,
        isUpdateSuccess: false,
        isUpdateError: true,
      };
    case types.FETCH_USER_ROLES_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.FETCH_USER_ROLES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isFetchSuccess: true,
        isFetchError: false,
        userRolesData: action.payload,
      };
    case types.FETCH_USER_ROLES_ERROR:
      return {
        ...state,
        isFetching: false,
        isFetchSuccess: false,
        isFetchError: true,
      };
    case types.FETCH_USER_ROLE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.FETCH_USER_ROLE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isFetchSuccess: true,
        isFetchError: false,
        userRoleData: action.payload,
      };
    case types.FETCH_USER_ROLE_ERROR:
      return {
        ...state,
        isFetching: false,
        isFetchSuccess: false,
        isFetchError: true,
      };
    case types.DELETE_USER_ROLE_REQUEST:
      return {
        ...state,
        isDeleting: true,
      };
    case types.DELETE_USER_ROLE_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        isDeleteSuccess: true,
        isDeleteError: false,
      };
    case types.DELETE_USER_ROLE_ERROR:
      return {
        ...state,
        isDeleting: false,
        isDeleteSuccess: false,
        isDeleteError: true,
      };
    case types.RESET:
      return {
        ...state,
        isDeleting: false,
        isDeleteSuccess: false,
        isDeleteError: false,
        isAdding: false,
        isAddSuccess: false,
        isAddError: false,
        isUpdating: false,
        isUpdateSuccess: false,
        isUpdateError: false,
        userRoleData: null,
        userRolesData: [],
      };
    default:
      return state;
  }
}

const userRoleReducer = combineReducers({
  userRole: userRole,
});

export default userRoleReducer;
