const ADD_USER_ROLE_REQUEST = "ADD_USER_ROLE_REQUEST";
const ADD_USER_ROLE_SUCCESS = "DD_USER_ROLE_SUCCESS";
const ADD_USER_ROLE_ERROR = "ADD_USER_ROLE_ERROR";

const DELETER_USER_ROLE_REQUEST = "DELETER_USER_ROLE_REQUEST";
const DELETER_USER_ROLE_SUCCESS = "DELETER_USER_ROLE_SUCCESS";
const DELETER_USER_ROLE_ERROR = "DELETER_USER_ROLE_ERROR";

const FETCH_USER_ROLES_REQUEST = "FETCH_USER_ROLES_REQUEST";
const FETCH_USER_ROLES_SUCCESS = "FETCH_USER_ROLES_SUCCESS";
const FETCH_USER_ROLES_ERROR = "FETCH_USER_ROLES_ERROR";

const FETCH_USER_ROLE_REQUEST = "FETCH_USER_ROLE_REQUEST";
const FETCH_USER_ROLE_SUCCESS = "FETCH_USER_ROLE_SUCCESS";
const FETCH_USER_ROLE_ERROR = "FETCH_USER_ROLE_ERROR";

const UPDATE_USER_ROLE_REQUEST = "UPDATE_USER_ROLE_REQUEST";
const UPDATE_USER_ROLE_SUCCESS = "UPDATE_USER_ROLE_SUCCESS";
const UPDATE_USER_ROLE_ERROR = "UPDATE_USER_ROLE_ERROR";

const RESET = "RESET";

export default {
  ADD_USER_ROLE_REQUEST,
  ADD_USER_ROLE_SUCCESS,
  ADD_USER_ROLE_ERROR,
  DELETER_USER_ROLE_REQUEST,
  DELETER_USER_ROLE_SUCCESS,
  DELETER_USER_ROLE_ERROR,
  FETCH_USER_ROLES_REQUEST,
  FETCH_USER_ROLES_SUCCESS,
  FETCH_USER_ROLES_ERROR,
  UPDATE_USER_ROLE_REQUEST,
  UPDATE_USER_ROLE_SUCCESS,
  UPDATE_USER_ROLE_ERROR,
  FETCH_USER_ROLE_REQUEST,
  FETCH_USER_ROLE_SUCCESS,
  FETCH_USER_ROLE_ERROR,
  RESET,
};
