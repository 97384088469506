import types from "./types";
import { ApiService } from "../../../utils";
import dataTablesBootstrap from "datatables.net-bs";

const PATH = "Reservation";

/* FETCH RESERVATIONS*/

export const fetchReservations = () => async (dispatch) => {
  dispatch(fetchReservationsRequest());
  try {
    const request = await ApiService.apiCall(
      `${PATH}/GetAllReservations?pageNumber=1&pageSize=1000`,
      {},
      false,
      "GET"
    );
    if (request.statusCode === 200) {
      dispatch(fetchReservationsSuccess(request));
    } else {
      dispatch(fetchReservationsError(request.err));
    }
  } catch (err) {
    dispatch(fetchReservationsError(err));
  }
};

const fetchReservationsRequest = () => {
  return {
    type: types.FETCH_RESERVATIONS_REQUEST,
  };
};

const fetchReservationsSuccess = (data) => {
  return {
    type: types.FETCH_RESERVATIONS_SUCCESS,
    payload: data.responseObject,
  };
};

const fetchReservationsError = (error) => {

  return {
    type: types.FETCH_RESERVATIONS_ERROR,
    payload: error.message,
    status: error.status,
  };
};

/*FETCH RESERVATION BY COLLEGIATE*/

export const fetchReservationsByCollegiate = (idCollegiate) => async (dispatch) => {
  dispatch(fetchReservationsByCollegiateRequest());
  try {
    const request = await ApiService.apiCall(
      `${PATH}/GetAllReservationsByCollegiate?idUser=${idCollegiate}`,
      {},
      false,
      "GET"
    );
    if (request.statusCode === 200) {
      dispatch(fetchReservationsByCollegiateSuccess(request));
    } else {
      dispatch(fetchReservationsByCollegiateError(request.err));
    }
  } catch (err) {
    dispatch(fetchReservationsByCollegiateError(err));
  }
};

const fetchReservationsByCollegiateRequest = () => {
  return {
    type: types.FETCH_RESERVATION_COLLEGIATE_REQUEST,
  };
};

const fetchReservationsByCollegiateSuccess = (data) => {
  return {
    type: types.FETCH_RESERVATION_COLLEGIATE_SUCCESS,
    payload: data.responseObject,
  };
};

const fetchReservationsByCollegiateError = (error) => {

  return {
    type: types.FETCH_RESERVATION_COLLEGIATE_ERROR,
    payload: error.message,
    status: error.status,
  };
};

/*FETCH SINGLE RESERVATION*/

export const fetchReservation = (reservationId = {}) => async (dispatch) => {

  dispatch(fetchReservationRequest());
  try {
    const request = await ApiService.apiCall(`${PATH}/GetReservation/${reservationId}?isDetailed=true`, {}, false, 'GET');
    if (request.statusCode == 200) {
      dispatch(fetchReservationSuccess(request));
    } else {
      dispatch(fetchReservationError(request.err));
    }
  } catch (err) {
    dispatch(fetchReservationError(err));
  }
};

const fetchReservationRequest = () => {
  return {
    type: types.FETCH_RESERVATION_REQUEST,
  };
};

const fetchReservationSuccess = (data) => {
  return {
    type: types.FETCH_RESERVATION_SUCCESS,
    payload: data.responseObject,
  };
};

const fetchReservationError = (error) => {
  return {
    type: types.FETCH_RESERVATION_ERROR,
    payload: error.message,
    status: error.status,
  };
};

/*ADD RESERVATION*/

export const addReservation = (reservation = {}) => async (dispatch) => {  
  
  dispatch(AddReservationRequest());
  try {
    const request = await ApiService.apiCall(`${PATH}/CreateReservation`, reservation);
    if (request.statusCode === 200) {
      dispatch(AddReservationSuccess(request));
    } else {
      dispatch(AddReservationError(request.err));
    }
  } catch (err) {
    dispatch(AddReservationError(err));
  }
};

const AddReservationRequest = () => {
  return {
    type: types.ADD_RESERVATION_REQUEST,
  };
};

const AddReservationSuccess = (data) => {
  return {
    type: types.ADD_RESERVATION_SUCCESS,
    payload: data.message,
  };
};

const AddReservationError = (error) => {
  return {
    type: types.ADD_RESERVATION_ERROR,
    payload: error,
    status: error.status,
  };
};

/*Update RESERVATION*/

export const updateReservation = (data = {}, idReservation = null) => async (dispatch) => {
  dispatch(UpdateReservationRequest());
  try {
    const request = await ApiService.apiCall(`${PATH}/UpdateReservation/${idReservation}/`, data, false, 'PUT');
    if (request.statusCode === 200) {
      dispatch(UpdateReservationSuccess(request));
    } else {
      dispatch(UpdateReservationError(request.err));
    }
  } catch (err) {
    dispatch(UpdateReservationError(err));
  }
};

const UpdateReservationRequest = () => {
  return {
    type: types.UPDATE_RESERVATION_REQUEST,
  };
};

const UpdateReservationSuccess = (data) => {
  return {
    type: types.UPDATE_RESERVATION_SUCCESS,
    payload: data.message,
  };
};

const UpdateReservationError = (error) => {
  return {
    type: types.UPDATE_RESERVATION_ERROR,
    payload: error,
    status: error.status,
  };
};

/*DELETE RESERVATION*/

export const deleteReservation = (idReservation = {}, observation="") => async (dispatch) => {
  dispatch(DeleteReservationRequest());
  try {
    const request = await ApiService.apiCall(
      `${PATH}/DeleteReservation/${idReservation}/${observation}`,
      {},
      false,
      "DELETE"
    );    if (request.statusCode === 200) {
      dispatch(DeleteReservationSuccess(request));
    } else {
      dispatch(DeleteReservationError(request.err));
    }
  } catch (err) {
    dispatch(DeleteReservationError(err));
  }
};

const DeleteReservationRequest = () => {
  return {
    type: types.DELETE_RESERVATION_REQUEST,
  };
};

const DeleteReservationSuccess = (data) => {
  return {
    type: types.DELETE_RESERVATION_SUCCESS,
    payload: data.message,
  };
};

const DeleteReservationError = (error) => {
  return {
    type: types.DELETE_RESERVATION_ERROR,
    payload: error.message,
    status: error.status,
  };
};

export function reset() {
  return {
    type: types.RESET,
  };
}

export function resetSingle() {
  return {
    type: types.RESET_SINGLE,
  };
}


export default {
  fetchReservations,
  fetchReservation,
  addReservation,
  updateReservation,
  deleteReservation,
  fetchReservationsByCollegiate,
  reset,
  resetSingle,
};
