import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'
import { bindActionCreators } from 'redux';
import { authActions } from "../../../store/modules/auth";


/**
 * Router for only guest stuff like Login/Register
 * If not guest - redirects to home
 */

class GuestRoute extends Component {
  render() {
    const {
      isAuthenticated,
      component: Component,
      ...props
    } = this.props;
    return (
      <Route
        {...props}
        render={props =>
          !isAuthenticated
            ? <Component {...props} />
            : (
              <Redirect to={{
                pathname: '/',
                state: { from: props.location },
              }} />
            )
        }
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.authState.auth.isAuthenticated,
    currentUser: state.authState.auth.currentUser,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      verifyToken: authActions.verifyToken,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(GuestRoute);
