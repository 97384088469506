const ADD_ROLE_REQUEST = "ADD_ROLE_REQUEST";
const ADD_ROLE_SUCCESS = "ADD_ROLE_SUCCESS";
const ADD_ROLE_ERROR = "ADD_ROLE_ERROR";

const DELETE_ROLE_REQUEST = "DELETE_ROLE_REQUEST";
const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
const DELETE_ROLE_ERROR = "DELETE_ROLE_ERROR";

const FETCH_ROLES_REQUEST = "FETCH_ROLES_REQUEST";
const FETCH_ROLES_SUCCESS = "FETCH_ROLES_SUCCESS";
const FETCH_ROLES_ERROR = "FETCH_ROLES_ERROR";

const FETCH_ROLE_REQUEST = "FETCH_ROLE_REQUEST";
const FETCH_ROLE_SUCCESS = "FETCH_ROLE_SUCCESS";
const FETCH_ROLE_ERROR = "FETCH_ROLE_ERROR";

const UPDATE_ROLE_REQUEST = "UPDATE_ROLE_REQUEST";
const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS";
const UPDATE_ROLE_ERROR = "UPDATE_ROLE_ERROR";

const RESET = "RESET";

export default {
  ADD_ROLE_REQUEST,
  ADD_ROLE_SUCCESS,
  ADD_ROLE_ERROR,
  DELETE_ROLE_REQUEST,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_ERROR,
  FETCH_ROLES_REQUEST,
  FETCH_ROLES_SUCCESS,
  FETCH_ROLES_ERROR,
  UPDATE_ROLE_REQUEST,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_ERROR,
  FETCH_ROLE_REQUEST,
  FETCH_ROLE_SUCCESS,
  FETCH_ROLE_ERROR,
  RESET,
};
