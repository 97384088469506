const ADD_USER_REQUEST = "ADD_USER_REQUEST";
const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
const ADD_USER_ERROR = "ADD_USER_ERROR";

const ADD_USERS_REQUEST = "ADD_USERS_REQUEST";
const ADD_USERS_SUCCESS = "ADD_USERS_SUCCESS";
const ADD_USERS_ERROR = "ADD_USERS_ERROR";

const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
const DELETE_USER_ERROR = "DELETE_USER_ERROR";

const FETCH_USERS_REQUEST = "FETCH_USERS_REQUEST";
const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
const FETCH_USERS_ERROR = "FETCH_USERS_ERROR";

const FETCH_USER_REQUEST = "FETCH_USER_REQUEST";
const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
const FETCH_USER_ERROR = "FETCH_USER_ERROR";

const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";

const RESET = "RESET";

export default {
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  ADD_USER_ERROR,
  ADD_USERS_REQUEST,
  ADD_USERS_SUCCESS,
  ADD_USERS_ERROR,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_ERROR,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_ERROR,
  RESET,
};
