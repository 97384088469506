const LOGIN_REQUEST = "LOGIN_REQUEST";
const LOGIN_ERROR = "LOGIN_ERROR";
const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGIN_SUCCESS_ROLE = "LOGIN_SUCCESS_ROLE";
const LOGOUT_REQUEST = "LOGOUT_REQUEST";
const LOGOUT_ERROR = "LOGOUT_ERROR";
const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
const AUTH_PROFILE = "AUTH_PROFILE";
const AUTH_REQUEST_PROFILE = "AUTH_REQUEST_PROFILE";

export default {
  LOGIN_REQUEST,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGIN_SUCCESS_ROLE,
  LOGOUT_REQUEST,
  LOGOUT_ERROR,
  LOGOUT_SUCCESS,
  AUTH_PROFILE,
  AUTH_REQUEST_PROFILE,
};
