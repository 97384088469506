import { combineReducers } from "redux";
import types from "./types";

const initialState = {
  isDeleting: false,
  isDeleteSuccess: false,
  isDeleteError: false,
  isAdding: false,
  isAddSuccess: false,
  isAddError: false,
  isUpdating: false,
  isUpdateSuccess: false,
  isUpdateError: false,
  isFetching: false,
  isFetchSuccess: false,
  isFetchError: false,
  isFetchingSingle: false,
  isFetchSingleSuccess: false,
  isFetchSingleError: false,
  seasonsData: [],
  seasonData: [],
};

export function season(state = initialState, action) {
  switch (action.type) {
    case types.ADD_SEASON_REQUEST:
      return {
        ...state,
        isAdding: true,
      };
    case types.ADD_SEASON_SUCCESS:
      return {
        ...state,
        isAdding: false,
        isAddSuccess: true,
        isAddError: false,
      };
    case types.ADD_SEASON_ERROR:
      return {
        ...state,
        isAdding: false,
        isAddSuccess: false,
        isAddError: true,
      };
    case types.UPDATE_SEASON_REQUEST:
      return {
        ...state,
        isUpdating: true,
      };
    case types.UPDATE_SEASON_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        isUpdateSuccess: true,
        isUpdateError: false,
      };
    case types.UPDATE_SEASON_ERROR:
      return {
        ...state,
        isUpdating: false,
        isUpdateSuccess: false,
        isUpdateError: true,
      };
    case types.FETCH_SEASONS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.FETCH_SEASONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isFetchSuccess: true,
        isFetchError: false,
        seasonsData: action.payload,
      };
    case types.FETCH_SEASONS_ERROR:
      return {
        ...state,
        isFetching: false,
        isFetchSuccess: false,
        isFetchError: true,
        seasonsData: [],
      };
    case types.FETCH_SEASON_REQUEST:
      return {
        ...state,
        isFetchingSingle: true,
      };
    case types.FETCH_SEASON_SUCCESS:
      return {
        ...state,
        isFetchingSingle: false,
        isFetchSingleSuccess: true,
        isFetchSingleError: false,
        seasonData: action.payload,
      };
    case types.FETCH_SEASON_ERROR:
      return {
        ...state,
        isFetchingSingle: false,
        isFetchSingleSuccess: false,
        isFetchSingleError: true,
      };
    case types.DELETE_SEASON_REQUEST:
      return {
        ...state,
        isDeleting: true,
      };
    case types.DELETE_SEASON_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        isDeleteSuccess: true,
        isDeleteError: false,
      };
    case types.DELETE_SEASON_ERROR:
      return {
        ...state,
        isDeleting: false,
        isDeleteSuccess: false,
        isDeleteError: true,
      };
    case types.RESET:
      return {
        ...state,
        isDeleting: false,
        isDeleteSuccess: false,
        isDeleteError: false,
        isAdding: false,
        isAddSuccess: false,
        isAddError: false,
        isUpdating: false,
        isUpdateSuccess: false,
        isUpdateError: false,
        seasonData: [],
        seasonsData: [],
      };
      case types.RESET_SINGLE_SEASON:
        return {
          ...state,
          seasonData: [],
        }; 
    default:
      return state;
  }
}

const seasonReducer = combineReducers({
  season: season,
});

export default seasonReducer;
