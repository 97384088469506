import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import { routerMiddleware, connectRouter } from 'connected-react-router';
import * as reducers from "./modules";
import ReduxPromise from 'redux-promise';
import thunk from 'redux-thunk';

import reducer from "./modules/global/reducers";


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default function configureStore(initialState = {}, history) {

  const rootReducer = (history) => combineReducers({
      ...reducers,
      reducer: reducer,  
      router: connectRouter(history),
    });
  
    const customMiddleWare = store => next => action => {
  
      if (action.status && action.status === 401) {
        store.dispatch({ type: 'LOGOUT_REQUEST' })
      } else {
        next(action);
      }
    };
  
    return createStore(
      rootReducer(history),
      initialState,
      composeEnhancers(
        applyMiddleware(
          routerMiddleware(history),
          ReduxPromise,
          customMiddleWare,
          thunk,
        ),
      ),
    );
  
  }

