import types from "./types";
import { ApiService } from "../../../utils";

const PATH = "Parameter";

/*FETCH PARAMETER*/

export const fetchParameters = (parameter = {}) => async (dispatch) => {
  dispatch(fetchParametersRequest());
  try {
    const request = await ApiService.apiCall(`${PATH}/GetAllParameters`, parameter, false, 'GET');
    if (request.statusCode === 200) {
      dispatch(fetchParametersSuccess(request));
    } else {
      
      dispatch(fetchParametersError(request.err));
    }
  } catch (err) {
    dispatch(fetchParametersError(err));
  }
};

const fetchParametersRequest = () => {
  return {
    type: types.FETCH_PARAMETERS_REQUEST,
  };
};

const fetchParametersSuccess = (data) => {
  return {
    type: types.FETCH_PARAMETERS_SUCCESS,
    payload: data.responseObject,
  };
};

const fetchParametersError = (error) => {
  return {
    type: types.FETCH_PARAMETERS_ERROR,
    payload: error.message,
    status: error.status,
  };
};

/*FETCH SINGLE PARAMETER*/

export const fetchParameter = (parameterId = null) => async (dispatch) => {
  dispatch(fetchParameterRequest());
  try {
    const request = await ApiService.apiCall(`${PATH}/GetParameter/${parameterId}`, {}, false, 'GET');
    if (request.statusCode === 200) {
      dispatch(fetchParameterSuccess(request));
    } else {
      dispatch(fetchParameterError(request.err));
    }
  } catch (err) {
    dispatch(fetchParameterError(err));
  }
};

const fetchParameterRequest = () => {
  return {
    type: types.FETCH_PARAMETER_REQUEST,
  };
};

const fetchParameterSuccess = (data) => {
  return {
    type: types.FETCH_PARAMETER_SUCCESS,
    payload: data.responseObject,
  };
};

const fetchParameterError = (error) => {
  return {
    type: types.FETCH_PARAMETER_ERROR,
    payload: error.message,
    status: error.status,
  };
};


/*UPDATE PARAMETer*/

export const updateParameter = (parameterData = {}, parameterId=null) => async (dispatch) => {
  dispatch(updateParameterRequest());
  try {
    const request = await ApiService.apiCall(`${PATH}/UpdateParameter/${parameterId}`, parameterData, false, 'PUT');
    if (request.statusCode === 200) {
      dispatch(updateParameterSuccess(request));
    } else {
      dispatch(updateParameterError(request.err));
    }
  } catch (err) {
    dispatch(updateParameterError(err));
  }
};

const updateParameterRequest = () => {
  return {
    type: types.UPDATE_PARAMETER_REQUEST,
  };
};

const updateParameterSuccess = (data) => {
  return {
    type: types.UPDATE_PARAMETER_SUCCESS,
    payload: data.message,
  };
};

const updateParameterError = (error) => {
  return {
    type: types.UPDATE_PARAMETER_ERROR,
    payload: error.message,
    status: error.status,
  };
};

/* DELETE PARAMETER*/

export const deleteParameter = (parameterId = null) => async (dispatch) => {
  dispatch(deleteParameterRequest());

  try {
    const request = await ApiService.apiCall(
      `${PATH}/DeleteHighParameter/${parameterId}`,
      {},
      false,
      "DELETE"
    );
    if (request.statusCode === 200) {
      dispatch(deleteParameterSuccess(request));
    } else {
      dispatch(deleteParameterError(request.err));
    }
  } catch (err) {
    dispatch(deleteParameterError(err));
  }
};

const deleteParameterRequest = () => {
  return {
    type: types.DELETE_PARAMETER_REQUEST,
  };
};

const deleteParameterSuccess = (data) => {
  return {
    type: types.DELETE_PARAMETER_SUCCESS,
    payload: data.message,
  };
};

const deleteParameterError = (error) => {
  return {
    type: types.DELETE_PARAMETER_ERROR,
    payload: error.message,
    status: error.status,
  };
};


export function reset() {
    return {
      type: types.RESET,
    };
  }


export function resetSingleParameter() {
  return {
    type: types.RESET_SINGLE_PARAMETER,
  };
}

export default {
  deleteParameter,
  updateParameter,
  fetchParameters,
  fetchParameter,
  reset,
  resetSingleParameter
};
