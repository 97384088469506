import types from "./types";
import { ApiService } from "../../../utils";

const USER_PATH = "User";

/*FETCH USER*/

export const fetchUsers = (rol = {}) => async (dispatch) => {
  dispatch(fetchUsersRequest());
  try {
    const request = await ApiService.apiCall(`${USER_PATH}/GetAllUsers?pageNumber=1&pageSize=1000`, rol, false, 'GET');
    if (request.statusCode === 200) {
      dispatch(fetchUsersSuccess(request));
    } else {
      dispatch(fetchUsersError(request.err));
    }
  } catch (err) {
    dispatch(fetchUsersError(err));
  }
};

const fetchUsersRequest = () => {
  return {
    type: types.FETCH_USERS_REQUEST,
  };
};

const fetchUsersSuccess = (data) => {
  return {
    type: types.FETCH_USERS_SUCCESS,
    payload: data.responseObject,
  };
};

const fetchUsersError = (error) => {
  return {
    type: types.FETCH_USERS_ERROR,
    payload: error.message,
    status: error.status,
  };
};

/*FETCH SINGLE USER*/

export const fetchUser = (userId = {}) => async (dispatch) => {
  dispatch(fetchUserRequest());
  try {
    const request = await ApiService.apiCall(`${USER_PATH}/GetUser/${userId}?isDetailed=true`, {}, false, 'GET');
    if (request.statusCode === 200) {
      dispatch(fetchUserSuccess(request));
    } else {
      dispatch(fetchUserError(request.err));
    }
  } catch (err) {
    dispatch(fetchUserError(err));
  }
};

const fetchUserRequest = () => {
  return {
    type: types.FETCH_USER_REQUEST,
  };
};

const fetchUserSuccess = (data) => {
  return {
    type: types.FETCH_USER_SUCCESS,
    payload: data.responseObject,
  };
};

const fetchUserError = (error) => {
  return {
    type: types.FETCH_USER_ERROR,
    payload: error.message,
    status: error.status,
  };
};

/*ADD USER*/

export const addUser = (user = {}) => async (dispatch) => {  
  dispatch(AddUserRequest());
  try {
    const request = await ApiService.apiCall(`${USER_PATH}/CreateUser`, user);
    if (request.statusCode === 200) {
      dispatch(AddUserSuccess(request));
    } else {
      dispatch(AddUserError(request.err));
    }
  } catch (err) {
    dispatch(AddUserError(err));
  }
};

const AddUserRequest = () => {
  return {
    type: types.ADD_USER_REQUEST,
  };
};

const AddUserSuccess = (data) => {
  return {
    type: types.ADD_USER_SUCCESS,
    payload: data.message,
  };
};

const AddUserError = (error) => {
  return {
    type: types.ADD_USER_ERROR,
    payload: error.message,
    status: error.status,
  };
};

/*ADD USERS*/

export const addUsers = (bulkUserList = []) => async (dispatch) => {
  dispatch(AddUsersRequest());
  try {
    const request = await ApiService.apiCall(`${USER_PATH}/BulkUser`, bulkUserList);
    if (request.statusCode === 200) {
      dispatch(AddUsersSuccess(request));
    } else {
      dispatch(AddUsersError(request.err));
    }
  } catch (err) {
    dispatch(AddUsersError(err));
  }
};

const AddUsersRequest = () => {
  return {
    type: types.ADD_USERS_REQUEST,
  };
};

const AddUsersSuccess = (data) => {
  return {
    type: types.ADD_USERS_SUCCESS,
    payload: data.message,
  };
};

const AddUsersError = (error) => {
  return {
    type: types.ADD_USERS_ERROR,
    payload: error.message,
    status: error.status,
  };
};


/*UPDATE USER*/

export const updateUser = (user = {}, userId=null) => async (dispatch) => {
  dispatch(updateUserRequest());
  try {
    const request = await ApiService.apiCall(`${USER_PATH}/UpdateUser/${userId}`, user, false, 'PUT');
    if (request.statusCode === 200) {
      dispatch(updateUserSuccess(request));
    } else {
      dispatch(updateUserError(request.err));
    }
  } catch (err) {
    dispatch(updateUserError(err));
  }
};

const updateUserRequest = () => {
  return {
    type: types.UPDATE_USER_REQUEST,
  };
};

const updateUserSuccess = (data) => {
  return {
    type: types.UPDATE_USER_SUCCESS,
    payload: data.message,
  };
};

const updateUserError = (error) => {
  return {
    type: types.UPDATE_USER_ERROR,
    payload: error.message,
    status: error.status,
  };
};

/* DELETE ROL*/

export const deleteUser = (idUser = null) => async (dispatch) => {
  dispatch(deleteUserRequest());

  try {
    const request = await ApiService.apiCall(
      `${USER_PATH}/DeleteUser/${idUser}`,
      {},
      false,
      "DELETE"
    );
    if (request.statusCode === 200) {
      dispatch(deleteUserSuccess(request));
    } else {
      dispatch(deleteUserError(request.err));
    }
  } catch (err) {
    dispatch(deleteUserError(err));
  }
};

const deleteUserRequest = () => {
  return {
    type: types.DELETE_USER_REQUEST,
  };
};

const deleteUserSuccess = (data) => {
  return {
    type: types.DELETE_USER_SUCCESS,
    payload: data.message,
  };
};

const deleteUserError = (error) => {
  return {
    type: types.DELETE_USER_ERROR,
    payload: error.message,
    status: error.status,
  };
};

export function reset() {
  return {
    type: types.RESET,
  };
}


export default {
  fetchUsers,
  fetchUser,
  addUser,
  addUsers,
  updateUser,
  deleteUser,
  reset,
};
