const ADD_SEASON_REQUEST = "ADD_SEASON_REQUEST";
const ADD_SEASON_SUCCESS = "ADD_SEASON_SUCCESS";
const ADD_SEASON_ERROR = "ADD_SEASON_ERROR";

const DELETE_SEASON_REQUEST = "DELETE_SEASON_REQUEST";
const DELETE_SEASON_SUCCESS = "DELETE_SEASON_SUCCESS";
const DELETE_SEASON_ERROR = "DELETE_SEASON_ERROR";

const FETCH_SEASONS_REQUEST = "FETCH_SEASONS_REQUEST";
const FETCH_SEASONS_SUCCESS = "FETCH_SEASONS_SUCCESS";
const FETCH_SEASONS_ERROR = "FETCH_SEASONS_ERROR";

const FETCH_SEASON_REQUEST = "FETCH_SEASON_REQUEST";
const FETCH_SEASON_SUCCESS = "FETCH_SEASON_SUCCESS";
const FETCH_SEASON_ERROR = "FETCH_SEASON_ERROR";

const UPDATE_SEASON_REQUEST = "UPDATE_SEASON_REQUEST";
const UPDATE_SEASON_SUCCESS = "UPDATE_SEASON_SUCCESS";
const UPDATE_SEASON_ERROR = "UPDATE_SEASON_ERROR";

const RESET = "RESET";

const RESET_SINGLE_SEASON = "RESET_SINGLE_SEASON";

export default {
 ADD_SEASON_REQUEST,
  ADD_SEASON_SUCCESS,
  ADD_SEASON_ERROR,
  DELETE_SEASON_REQUEST,
  DELETE_SEASON_SUCCESS,
  DELETE_SEASON_ERROR,
  FETCH_SEASONS_REQUEST,
  FETCH_SEASONS_SUCCESS,
  FETCH_SEASONS_ERROR,
  UPDATE_SEASON_REQUEST,
  UPDATE_SEASON_SUCCESS,
  UPDATE_SEASON_ERROR,
  FETCH_SEASON_REQUEST,
  FETCH_SEASON_SUCCESS,
  FETCH_SEASON_ERROR,
  RESET,
  RESET_SINGLE_SEASON,
};
