const FETCH_PARAMETERS_REQUEST = "FETCH_PARAMETERS_REQUEST";
const FETCH_PARAMETERS_SUCCESS = "FETCH_PARAMETERS_SUCCESS";
const FETCH_PARAMETERS_ERROR = "FETCH_PARAMETERS_ERROR";

const FETCH_PARAMETER_REQUEST = "FETCH_PARAMETER_REQUEST";
const FETCH_PARAMETER_SUCCESS = "FETCH_PARAMETER_SUCCESS";
const FETCH_PARAMETER_ERROR = "FETCH_PARAMETER_ERROR";

const UPDATE_PARAMETER_REQUEST = "UPDATE_PARAMETER_REQUEST";
const UPDATE_PARAMETER_SUCCESS = "UPDATE_PARAMETER_SUCCESS";
const UPDATE_PARAMETER_ERROR = "UPDATE_PARAMETER_ERROR";

const RESET = "RESET";

const RESET_SINGLE_PARAMETER = "RESET_SINGLE_PARAMETER";

export default {

  FETCH_PARAMETERS_REQUEST,
  FETCH_PARAMETERS_SUCCESS,
  FETCH_PARAMETERS_ERROR,
  UPDATE_PARAMETER_REQUEST,
  UPDATE_PARAMETER_SUCCESS,
  UPDATE_PARAMETER_ERROR,
  FETCH_PARAMETER_REQUEST,
  FETCH_PARAMETER_SUCCESS,
  FETCH_PARAMETER_ERROR,
  RESET,
  RESET_SINGLE_PARAMETER,
};
