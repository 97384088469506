import types from "./types";
import { ApiService } from "../../../utils";

const PATH = "Cabin";

/*FETCH CABIN*/

export const fetchCabins = (centerId = {}) => async (dispatch) => {
  dispatch(fetchCabinsRequest());
  try {
    const request = await ApiService.apiCall(`${PATH}/GetAllCabinFromRecreationCenter/${centerId}`, {}, false, 'GET');
    if (request.statusCode === 200) {
      dispatch(fetchCabinsSuccess(request));
    } else {
      dispatch(fetchCabinsError(request.err));
    }
  } catch (err) {
    dispatch(fetchCabinsError(err));
  }
};

const fetchCabinsRequest = () => {
  return {
    type: types.FETCH_CABINS_REQUEST,
  };
};

const fetchCabinsSuccess = (data) => {
  return {
    type: types.FETCH_CABINS_SUCCESS,
    payload: data.responseObject,
  };
};

const fetchCabinsError = (error) => {
  return {
    type: types.FETCH_CABINS_ERROR,
    payload: error.message,
    status: error.status,
  };
};

/*FETCH SINGLE CABIN*/

export const fetchCabin = (centerId=null , cabinId = null) => async (dispatch) => {
  dispatch(fetchCabinRequest());
  try {
    const request = await ApiService.apiCall(`${PATH}/GetCabin/${centerId}?idCabin=${cabinId}`, {}, false, 'GET');
    if (request.statusCode === 200) {
      dispatch(fetchCabinSuccess(request));
    } else {
      dispatch(fetchCabinError(request.err));
    }
  } catch (err) {
    dispatch(fetchCabinError(err));
  }
};

const fetchCabinRequest = () => {
  return {
    type: types.FETCH_CABIN_REQUEST,
  };
};

const fetchCabinSuccess = (data) => {
  return {
    type: types.FETCH_CABIN_SUCCESS,
    payload: data.responseObject,
  };
};

const fetchCabinError = (error) => {
  return {
    type: types.FETCH_CABIN_ERROR,
    payload: error.message,
    status: error.status,
  };
};

/*ADD CABIN*/

export const addCabin = (cabin = {}, centerId=null) => async (dispatch) => {
  
  dispatch(AddCabinRequest());
  try {
    const request = await ApiService.apiCall(`${PATH}/CreateCabin/${centerId}`, cabin);
    if (request.statusCode === 200) {
      dispatch(AddCabinSuccess(request));
    } else {
      dispatch(AddCabinError(request.err));
    }
  } catch (err) {
    dispatch(AddCabinError(err));
  }
};

const AddCabinRequest = () => {
  return {
    type: types.ADD_CABIN_REQUEST,
  };
};

const AddCabinSuccess = (data) => {
  return {
    type: types.ADD_CABIN_SUCCESS,
    payload: data.message,
  };
};

const AddCabinError = (error) => {
  return {
    type: types.ADD_CABIN_ERROR,
    payload: error.message,
    status: error.status,
  };
};


/*UPDATE CABIN*/

export const updateCabin = (cabinData = {}, cabinId=null) => async (dispatch) => {
  dispatch(updateCabinRequest());
  try {
    const request = await ApiService.apiCall(`${PATH}/UpdateCabin/${cabinId}`, cabinData, false, 'PUT');

    if (request.statusCode === 200) {
      dispatch(updateCabinSuccess(request));
    } else {
      dispatch(updateCabinError(request.err));
    }
  } catch (err) {

    dispatch(updateCabinError(err));
  }
};

const updateCabinRequest = () => {
  return {
    type: types.UPDATE_CABIN_REQUEST,
  };
};

const updateCabinSuccess = (data) => {
  return {
    type: types.UPDATE_CABIN_SUCCESS,
    payload: data.message,
  };
};

const updateCabinError = (error) => {
  return {
    type: types.UPDATE_CABIN_ERROR,
    payload: error.message,
    status: error.status,
  };
};

/* DELETE CABIN*/

const deleteCabin = (centerId=null, cabinId = null) => async (dispatch) => {
  dispatch(deleteCabinRequest());

  try {
    const request = await ApiService.apiCall(
      `${PATH}/DeleteCabin/${centerId}?idCabin=${cabinId}`,
      {},
      false,
      "DELETE"
    );
    if (request.statusCode === 200) {
      dispatch(deleteCabinSuccess(request));
    } else {
      dispatch(deleteCabinError(request.err));
    }
  } catch (err) {
    dispatch(deleteCabinError(err));
  }
};

const deleteCabinRequest = () => {
  return {
    type: types.DELETE_CABIN_REQUEST,
  };
};

const deleteCabinSuccess = (data) => {
  return {
    type: types.DELETE_CABIN_SUCCESS,
    payload: data.message,
  };
};

const deleteCabinError = (error) => {
  return {
    type: types.DELETE_CABIN_ERROR,
    payload: error.message,
    status: error.status,
  };
};

const deleteCabinReset = () => {
  return {
    type: types.DELETE_CABIN_ERROR_RESET
  }
}

export function reset() {
    return {
      type: types.RESET,
    };
  }

  export function resetSingleCabin() {
    return {
      type: types.RESET_SINGLE_CABIN,
    };
  }

export default {
  addCabin, 
  deleteCabin,
  deleteCabinReset,
  updateCabin, 
  fetchCabins,
  fetchCabin,
  reset,
  resetSingleCabin
};
