import { combineReducers } from "redux";
import types from "./types";

const initialState = {
  isDeleting: false,
  isDeleteSuccess: false,
  isDeleteError: false,
  isAdding: false,
  isAddSuccess: false,
  isAddError: false,
  isUpdating: false,
  isUpdateSuccess: false,
  isUpdateError: false,
  isFetching: false,
  isFetchSuccess: false,
  isFetchError: false,
  usersData: [],
  userData: null,
  userBulkResultMessage: null
};

export function user(state = initialState, action) {
  switch (action.type) {
    case types.ADD_USER_REQUEST:
      return {
        ...state,
        isAdding: true,
      };
    case types.ADD_USER_SUCCESS:
      return {
        ...state,
        isAdding: false,
        isAddSuccess: true,
        isAddError: false,
      };
    case types.ADD_USER_ERROR:
      return {
        ...state,
        isAdding: false,
        isAddSuccess: false,
        isAddError: true,
      };
    case types.ADD_USERS_REQUEST:
      return {
        ...state,
        isAdding: true,
      };
    case types.ADD_USERS_SUCCESS:
      return {
        ...state,
        userBulkResultMessage: action.payload,
        isAdding: false,
        isAddSuccess: true,
        isAddError: false,
      };
    case types.ADD_USERS_ERROR:
      return {
        ...state,
        isAdding: false,
        isAddSuccess: false,
        isAddError: true,
      };
    case types.UPDATE_USER_REQUEST:
      return {
        ...state,
        isUpdating: true,
      };
    case types.UPDATE_USER_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        isUpdateSuccess: true,
        isUpdateError: false,
      };
    case types.UPDATE_USER_ERROR:
      return {
        ...state,
        isUpdating: false,
        isUpdateSuccess: false,
        isUpdateError: true,
      };
    case types.FETCH_USERS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.FETCH_USERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isFetchSuccess: true,
        isFetchError: false,
        usersData: action.payload,
      };
    case types.FETCH_USERS_ERROR:
      return {
        ...state,
        isFetching: false,
        isFetchSuccess: false,
        isFetchError: true,
      };
    case types.FETCH_USER_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.FETCH_USER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isFetchSuccess: true,
        isFetchError: false,
        userData: action.payload,
      };
    case types.FETCH_USER_ERROR:
      return {
        ...state,
        isFetching: false,
        isFetchSuccess: false,
        isFetchError: true,
      };
    case types.DELETE_USER_REQUEST:
      return {
        ...state,
        isDeleting: true,
      };
    case types.DELETE_USER_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        isDeleteSuccess: true,
        isDeleteError: false,
      };
    case types.DELETE_USER_ERROR:
      return {
        ...state,
        isDeleting: false,
        isDeleteSuccess: false,
        isDeleteError: true,
      };
    case types.RESET:
      return {
        ...state,
        isDeleting: false,
        isDeleteSuccess: false,
        isDeleteError: false,
        isAdding: false,
        isAddSuccess: false,
        isAddError: false,
        isUpdating: false,
        isUpdateSuccess: false,
        isUpdateError: false,
        userData: null,
        userBulkResultMessage: ''
      };
    default:
      return state;
  }
}

const userReducer = combineReducers({
  user: user,
});

export default userReducer;
