const FETCH_RESERVATIONS_REQUEST = "FETCH_RESERVATIONS_REQUEST"
const FETCH_RESERVATIONS_SUCCESS = "FETCH_RESERVATIONS_SUCCESS"
const FETCH_RESERVATIONS_ERROR = "FETCH_RESERVATIONS_ERROR"

const FETCH_RESERVATION_REQUEST = "FETCH_RESERVATION_REQUEST"
const FETCH_RESERVATION_SUCCESS = "FETCH_RESERVATION_SUCCESS"
const FETCH_RESERVATION_ERROR = "FETCH_RESERVATION_ERROR"

const FETCH_RESERVATION_COLLEGIATE_REQUEST = "FETCH_RESERVATION_COLLEGIATE_REQUEST"
const FETCH_RESERVATION_COLLEGIATE_SUCCESS = "FETCH_RESERVATION_COLLEGIATE_SUCCESS"
const FETCH_RESERVATION_COLLEGIATE_ERROR = "FETCH_RESERVATION_COLLEGIATE_ERROR"

const ADD_RESERVATION_REQUEST = "ADD_RESERVATION_REQUEST"
const ADD_RESERVATION_SUCCESS = "ADD_RESERVATION_SUCCESS"
const ADD_RESERVATION_ERROR = "ADD_RESERVATION_ERROR"

const UPDATE_RESERVATION_REQUEST = "UPDATE_RESERVATIONS_REQUEST"
const UPDATE_RESERVATION_SUCCESS = "UPDATE_RESERVATIONS_SUCCESS"
const UPDATE_RESERVATION_ERROR = "UPDATE_RESERVATIONS_ERROR"

const DELETE_RESERVATION_REQUEST = "DELETE_RESERVATIONS_REQUEST"
const DELETE_RESERVATION_SUCCESS = "DELETE_RESERVATIONS_SUCCESS"
const DELETE_RESERVATION_ERROR = "DELETE_RESERVATIONS_ERROR"

const RESET = "RESET";
const RESET_SINGLE = "RESET_SINGLE";

export default {
 FETCH_RESERVATIONS_REQUEST,
 FETCH_RESERVATIONS_SUCCESS,
 FETCH_RESERVATIONS_ERROR,
 FETCH_RESERVATION_COLLEGIATE_REQUEST,
 FETCH_RESERVATION_COLLEGIATE_SUCCESS,
 FETCH_RESERVATION_COLLEGIATE_ERROR,
 FETCH_RESERVATION_REQUEST,
 FETCH_RESERVATION_SUCCESS,
 FETCH_RESERVATION_ERROR,
 ADD_RESERVATION_REQUEST,
 ADD_RESERVATION_SUCCESS,
 ADD_RESERVATION_ERROR,
 UPDATE_RESERVATION_REQUEST,
 UPDATE_RESERVATION_SUCCESS,
 UPDATE_RESERVATION_ERROR,
 DELETE_RESERVATION_REQUEST,
 DELETE_RESERVATION_SUCCESS,
 DELETE_RESERVATION_ERROR,
 RESET,
 RESET_SINGLE,
};
