import types from "./types";
import { ApiService } from "../../../utils";

const ROL_PATH = "Rol";

/*FETCH ROLE*/

export const fetchRoles = (rol = {}) => async (dispatch) => {
  dispatch(fetchRolesRequest());
  try {
    const request = await ApiService.apiCall(`${ROL_PATH}/GetAllRols?pageNumber=1&pageSize=100&isDetailed=true`, rol, false, 'GET');
    if (request.statusCode === 200) {
      dispatch(fetchRolesSuccess(request));
    } else {
      dispatch(fetchRolesError(request.err));
    }
  } catch (err) {
    dispatch(fetchRolesError(err));
  }
};

const fetchRolesRequest = () => {
  return {
    type: types.FETCH_ROLES_REQUEST,
  };
};

const fetchRolesSuccess = (data) => {
  return {
    type: types.FETCH_ROLES_SUCCESS,
    payload: data.responseObject,
  };
};

const fetchRolesError = (error) => {
  return {
    type: types.FETCH_ROLES_ERROR,
    payload: error.message,
    status: error.status,
  };
};

/*FETCH SINGLE ROLE*/

export const fetchRole = (roleId = {}) => async (dispatch) => {
  dispatch(fetchRoleRequest());
  try {
    const request = await ApiService.apiCall(`${ROL_PATH}/GetRol/${roleId}?isDetailed=true`, {}, false, 'GET');
    if (request.statusCode === 200) {
      dispatch(fetchRoleSuccess(request));
    } else {
      dispatch(fetchRoleError(request.err));
    }
  } catch (err) {
    dispatch(fetchRoleError(err));
  }
};

const fetchRoleRequest = () => {
  return {
    type: types.FETCH_ROLE_REQUEST,
  };
};

const fetchRoleSuccess = (data) => {
  return {
    type: types.FETCH_ROLE_SUCCESS,
    payload: data.responseObject,
  };
};

const fetchRoleError = (error) => {
  return {
    type: types.FETCH_ROLE_ERROR,
    payload: error.message,
    status: error.status,
  };
};

/*ADD ROLE*/

export const addRole = (role = {}) => async (dispatch) => {
  
  dispatch(AddRoleRequest());
  try {
    const request = await ApiService.apiCall(`${ROL_PATH}/CreateRol`, role);
    if (request.statusCode === 200) {
      dispatch(AddRoleSuccess(request));
    } else {
      dispatch(AddRoleError(request.err));
    }
  } catch (err) {
    dispatch(AddRoleError(err));
  }
};

const AddRoleRequest = () => {
  return {
    type: types.ADD_ROLE_REQUEST,
  };
};

const AddRoleSuccess = (data) => {
  return {
    type: types.ADD_ROLE_SUCCESS,
    payload: data.message,
  };
};

const AddRoleError = (error) => {
  return {
    type: types.ADD_ROLE_ERROR,
    payload: error.message,
    status: error.status,
  };
};


/*UPDATE ROLE*/

export const updateRole = (rol = {}, rolId=null) => async (dispatch) => {
  dispatch(updateRoleRequest());
  try {
    const request = await ApiService.apiCall(`${ROL_PATH}/UpdateRol/${rolId}`, rol, false, 'PUT');
    if (request.statusCode === 200) {
      dispatch(updateRoleSuccess(request));
    } else {
      dispatch(updateRoleError(request.err));
    }
  } catch (err) {
    dispatch(updateRoleError(err));
  }
};

const updateRoleRequest = () => {
  return {
    type: types.UPDATE_ROLE_REQUEST,
  };
};

const updateRoleSuccess = (data) => {
  return {
    type: types.UPDATE_ROLE_SUCCESS,
    payload: data.message,
  };
};

const updateRoleError = (error) => {
  return {
    type: types.UPDATE_ROLE_ERROR,
    payload: error.message,
    status: error.status,
  };
};

/* DELETE ROL*/

export const deleteRole = (idRol = null) => async (dispatch) => {
  dispatch(deleteRoleRequest());

  try {
    const request = await ApiService.apiCall(
      `${ROL_PATH}/DeleteRol/${idRol}`,
      {},
      false,
      "DELETE"
    );
    if (request.statusCode === 200) {
      dispatch(deleteRoleSuccess(request));
    } else {
      dispatch(deleteRoleError(request.err));
    }
  } catch (err) {
    dispatch(deleteRoleError(err));
  }
};

const deleteRoleRequest = () => {
  return {
    type: types.DELETE_ROLE_REQUEST,
  };
};

const deleteRoleSuccess = (data) => {
  return {
    type: types.DELETE_ROLE_SUCCESS,
    payload: data.message,
  };
};

const deleteRoleError = (error) => {
  return {
    type: types.DELETE_ROLE_ERROR,
    payload: error.message,
    status: error.status,
  };
};

export function reset() {
  return {
    type: types.RESET,
  };
}


export default {
  fetchRoles,
  fetchRole,
  addRole,
  updateRole,
  deleteRole,
  reset,
};
