import types from "./types";
import { ApiService } from "../../../utils";

export const fetchRequests = (requestedData) => async (dispatch) => {
  dispatch(fetchRequestsRequest());
  try {
    const request = await ApiService.apiCall(`Solicitude/GetAllSolicitudes/${requestedData}`, {...requestedData}, false, 'GET');
    if (request.statusCode === 200) {
      dispatch(fetchRequestsSuccess(request));
    } else if (request.statusCode === 204) {
      dispatch(fetchRequestsSuccess(request));
    } else {
      dispatch(fetchRequestsError(request.err));
    }
  } catch (err) {
    dispatch(fetchRequestsError(err));
  }
};

const fetchRequestsRequest = () => {
  return {
    type: types.FETCH_REQUESTS_REQUEST,
  };
};

const fetchRequestsSuccess = (data) => {
  return {
    type: types.FETCH_REQUESTS_SUCCESS,
    payload: data.responseObject,
  };
};

const fetchRequestsError = (error) => {
  return {
    type: types.FETCH_REQUESTS_ERROR,
    payload: error.message,
    status: error.status,
  };
};

export const updateRequest = (requestedData) => async (dispatch) => {
  dispatch(updateRequestRequest());
  try {
    const request = await ApiService.apiCall(`Solicitude/UpdateSolicitude/${requestedData.requestId}/status/${requestedData.status}/type/${requestedData.reportType}`, requestedData.observacion, false, 'PUT');
    if (request.statusCode === 200) {
      dispatch(updateRequestSuccess(request));
    } else {
      dispatch(updateRequestError(request.err));
    }
  } catch (err) {
    dispatch(updateRequestError(err));
  }
};

const updateRequestRequest = () => {
  return {
    type: types.UPDATE_REQUEST_REQUEST,
  };
};

const updateRequestSuccess = (data) => {
  return {
    type: types.UPDATE_REQUEST_SUCCESS,
    payload: data.message,
  };
};

const updateRequestError = (error) => {
  return {
    type: types.UPDATE_REQUEST_ERROR,
    payload: error.message,
    status: error.status,
  };
};

export function reset() {
    return {
      type: types.RESET,
    };
  }



export default {
  updateRequest,
  fetchRequests,
  reset,
};
