import types from "./types";
import { ApiService } from "../../../utils";

const PATH = "Cabin";

/*FETCH DATE*/

export const fetchDates = (cabinId = {}) => async (dispatch) => {
  dispatch(fetchDatesRequest());
  try {
    const request = await ApiService.apiCall(
      `${PATH}/GetAllBlockDates/${cabinId}/?pageNumber=1&pageSize=10`,
      {},
      false,
      "GET"
    );
    if (request.statusCode === 200) {
      dispatch(fetchDatesSuccess(request));
    } else {
      dispatch(fetchDatesError(request.err));
    }
  } catch (err) {
    dispatch(fetchDatesError(err));
  }
};

const fetchDatesRequest = () => {
  return {
    type: types.FETCH_DATES_REQUEST,
  };
};

const fetchDatesSuccess = (data) => {
  return {
    type: types.FETCH_DATES_SUCCESS,
    payload: data.responseObject,
  };
};

const fetchDatesError = (error) => {
  return {
    type: types.FETCH_DATES_ERROR,
    payload: error.message,
    status: error.status,
  };
};

/*FETCH SINGLE DATE*/

export const fetchDate = (cabinId = null) => async (dispatch) => {
  dispatch(fetchDateRequest());
  try {
    const request = await ApiService.apiCall(
      `${PATH}/GetBlockDate/?idCabin=${cabinId}`,
      {},
      false,
      "GET"
    );
    if (request.statusCode === 200) {
      dispatch(fetchDateSuccess(request));
    } else {
      dispatch(fetchDateError(request.err));
    }
  } catch (err) {
    dispatch(fetchDateError(err));
  }
};

const fetchDateRequest = () => {
  return {
    type: types.FETCH_DATE_REQUEST,
  };
};

const fetchDateSuccess = (data) => {
  return {
    type: types.FETCH_DATE_SUCCESS,
    payload: data.responseObject,
  };
};

const fetchDateError = (error) => {
  return {
    type: types.FETCH_DATE_ERROR,
    payload: error.message,
    status: error.status,
  };
};

/*ADD DATE*/

export const addDate = (date = {}, cabinId = null) => async (dispatch) => {
  dispatch(AddDateRequest());
  try {
    const request = await ApiService.apiCall(
      `${PATH}/CreateBlockDate/`,
      date
    );
    if (request.statusCode === 200) {
      dispatch(AddDateSuccess(request));
    } else {
      dispatch(AddDateError(request.err));
    }
  } catch (err) {
    dispatch(AddDateError(err));
  }
};

const AddDateRequest = () => {
  return {
    type: types.ADD_DATE_REQUEST,
  };
};

const AddDateSuccess = (data) => {
  return {
    type: types.ADD_DATE_SUCCESS,
    payload: data.message,
  };
};

const AddDateError = (error) => {
  return {
    type: types.ADD_DATE_ERROR,
    payload: error.message,
    status: error.status,
  };
};

/*UPDATE DATE*/

/*export const updateDate = (cabinData = {}, cabinId=null) => async (dispatch) => {
  dispatch(updateCabinRequest());
  try {
    const request = await ApiService.apiCall(`${PATH}/UpdateCabin/${cabinId}`, cabinData, false, 'PUT');

    if (request.statusCode === 200) {
      dispatch(updateCabinSuccess(request));
    } else {
      dispatch(updateCabinError(request.err));
    }
  } catch (err) {

    dispatch(updateCabinError(err));
  }
};

const updateCabinRequest = () => {
  return {
    type: types.UPDATE_DATE_REQUEST,
  };
};

const updateCabinSuccess = (data) => {
  return {
    type: types.UPDATE_DATE_SUCCESS,
    payload: data.message,
  };
};

const updateCabinError = (error) => {
  return {
    type: types.UPDATE_DATE_ERROR,
    payload: error.message,
    status: error.status,
  };
};*/

/* DELETE DATE*/

export const deleteDate = (cabinId = null) => async (dispatch) => {
  dispatch(deleteDateRequest());

  try {
    const request = await ApiService.apiCall(
      `${PATH}/DeleteBlockDate/${cabinId}`,
      {},
      false,
      "DELETE"
    );
    if (request.statusCode === 200) {
      dispatch(deleteDateSuccess(request));
    } else {
      dispatch(deleteDateError(request.err));
    }
  } catch (err) {
    dispatch(deleteDateError(err));
  }
};

const deleteDateRequest = () => {
  return {
    type: types.DELETE_DATE_REQUEST,
  };
};

const deleteDateSuccess = (data) => {
  return {
    type: types.DELETE_DATE_SUCCESS,
    payload: data.message,
  };
};

const deleteDateError = (error) => {
  return {
    type: types.DELETE_DATE_ERROR,
    payload: error.message,
    status: error.status,
  };
};

export function resetDates() {
  return {
    type: types.RESET,
  };
}

export function resetSingleDate() {
  return {
    type: types.RESET_SINGLE,
  };
}

export default {
  addDate,
  deleteDate,
  fetchDates,
  fetchDate,
  resetDates,
  resetSingleDate,
};
