/**
 * Class to operate with storage
 */

class StorageService {
    constructor() {
      this.storage = window.localStorage;
      this.token_key = 'token'
    }
  
    getKey(name) {
      return this.storage.getItem(name) || false;
    }
  
    setKey(name = 'token', value) {
      if (value) {
        this.storage.setItem(name, value);
      }
    }
  
    removeKey(name = this.token_key) {
      this.storage.removeItem(name);
    }
  
  }
  
  export default new StorageService();
  