import { combineReducers } from "redux";
import types from "./types";

const initialState = {
  isDeleting: false,
  isDeleteSuccess: false,
  isDeleteError: false,
  isAdding: false,
  isAddSuccess: false,
  isAddError: false,
  isUpdating: false,
  isUpdateSuccess: false,
  isUpdateError: false,
  isFetching: false,
  isFetchSuccess: false,
  isFetchError: false,
  cabinsData: [],
  cabinData: []
};

export function cabin(state = initialState, action) {
  switch (action.type) {
    case types.ADD_CABIN_REQUEST:
      return {
        ...state,
        isAdding: true,
      };
    case types.ADD_CABIN_SUCCESS:
      return {
        ...state,
        isAdding: false,
        isAddSuccess: true,
        isAddError: false,
      };
    case types.ADD_CABIN_ERROR:
      return {
        ...state,
        isAdding: false,
        isAddSuccess: false,
        isAddError: true,
      };
    case types.UPDATE_CABIN_REQUEST:
      return {
        ...state,
        isUpdating: true,
      };
    case types.UPDATE_CABIN_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        isUpdateSuccess: true,
        isUpdateError: false,
      };
    case types.UPDATE_CABIN_ERROR:
      return {
        ...state,
        isUpdating: false,
        isUpdateSuccess: false,
        isUpdateError: true,
      };
    case types.FETCH_CABINS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.FETCH_CABINS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isFetchSuccess: true,
        isFetchError: false,
        cabinsData: action.payload,
      };
    case types.FETCH_CABINS_ERROR:
      return {
        ...state,
        isFetching: false,
        isFetchSuccess: false,
        isFetchError: true,
      };
    case types.FETCH_CABIN_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.FETCH_CABIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isFetchSuccess: true,
        isFetchError: false,
        cabinData: action.payload,
      };
    case types.FETCH_CABIN_ERROR:
      return {
        ...state,
        isFetching: false,
        isFetchSuccess: false,
        isFetchError: true,
      };
    case types.DELETE_CABIN_REQUEST:
      return {
        ...state,
        isDeleting: true,
      };
    case types.DELETE_CABIN_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        isDeleteSuccess: true,
        isDeleteError: false,
      };
    case types.DELETE_CABIN_ERROR:
      return {
        ...state,
        isDeleting: false,
        isDeleteSuccess: false,
        isDeleteError: true,
      };
    case types.DELETE_CABIN_ERROR_RESET:
        return {
          ...state,
          isDeleteError: false,
        };
    case types.RESET:
      return {
        ...state,
        isDeleting: false,
        isDeleteSuccess: false,
        isDeleteError: false,
        isAdding: false,
        isAddSuccess: false,
        isAddError: false,
        isUpdating: false,
        isUpdateSuccess: false,
        isUpdateError: false,
        cabinData: [],
        cabinsData: [],
      };
    case types.RESET_SINGLE_CABIN:
      return{
        ...state,
        cabinData: [],
      }
    default:
      return state;
  }
}

const cabinReducer = combineReducers({
  cabin: cabin,
});

export default cabinReducer;
