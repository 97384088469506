import types from "./types";
import { ApiService, StorageService } from '../../../utils'
import {isEmpty} from 'lodash'

export async function login(user = {}) {

  const userLog = {
    ...user,
    email: "string",
    name: "string",
    lastName: "string",
    hashSalt: {
    hash: "string",
    salt: "string"
  }
  };

  try {
      
    const request = await ApiService.apiCall('Login/LoginUser', userLog);
    const {user, token, userRoles} = request.responseObject;
    const userId  = request.responseObject.user.idUser;
    const fullName = `${request.responseObject.user.name}`;
    const roles = userRoles;

    StorageService.setKey('token', token.token);
    StorageService.setKey('userId', userId);
    StorageService.setKey('fullName', fullName);
    StorageService.setKey('roles', JSON.stringify(roles));

    if(roles.length === 1){
      StorageService.setKey('role', JSON.stringify(roles[0])); 
    }

    return {
      type: types.LOGIN_SUCCESS,
      payload: {
        userId,
        roles, 
        fullName
      },
    }
  } catch (e) {
    return {
      type: types.LOGIN_ERROR,
      payload: e.message,
    }
  }
}

export async function setRole(role) {
  const roles = StorageService.getKey('roles');
  const allRoles = JSON.parse(roles);

  const selectedRole = allRoles.find(rol=> rol.name == role);
  StorageService.setKey('role', JSON.stringify(selectedRole));
  
  return {
    type: types.LOGIN_SUCCESS_ROLE,
  }
}

export async function logout() {
  StorageService.removeKey('token');
  StorageService.removeKey('role');
  StorageService.removeKey('roles');
  StorageService.removeKey('fullName')
  StorageService.removeKey('userId');
  return {
    type: types.LOGOUT_SUCCESS,
  }
}

export async function requestLogout(contextStatus) {

  return {
    type: types.LOGOUT_REQUEST,
    payload: contextStatus
  }
}

export async function requestLogin() {

  return {
    type: types.LOGIN_REQUEST,
  }
}

export function verifyToken() {
  if (!StorageService.getKey('token')) { //if no token - logout
    return logout();
  }
  try {
    //const user = await ApiService.verifyToken(StorageService.getKey('user'));

    return {
      type: types.AUTH_PROFILE,
      payload: {
        user: StorageService.getKey('user'),
        role: StorageService.getKey('role'),
        token: StorageService.getKey('token',)
      }

    }
  } catch (e) {
    //remove token and logout if invalid
    return logout();
  }

}

export default {
  requestLogin,
  requestLogout,
  login,
  logout,
  verifyToken,
  setRole,
};
