import { combineReducers } from "redux";
import types from "./types";

const initialState = {
  isAuthenticated: false,
  isFailure: false,
  isLoading: false,
  currentUser: null,
  isSessionExpired: false,
  roles: null,
  fullName: null
};

export function login(state = initialState, action) {
  switch (action.type) {
    case types.LOGIN_REQUEST: //successfull auth - either login or we have token already
      return {
        ...state,
        isAuthenticated: false,
        isFailure: false,
        isLoading: true,
      };
    case types.LOGIN_SUCCESS: //successfull auth - either login or we have token already
      return {
        ...state,
        isFailure: false,
        isLoading: false,
        isAuthenticated: action.payload.roles.length > 1 ? false : true,
        currentUser: action.payload.username,
        roles: action.payload.roles,
        fullName: action.payload.roles,
      };
      case types.LOGIN_SUCCESS_ROLE: //successfull auth - either login or we have token already
      return {
        ...state,
        isAuthenticated: true,
      };  
    case types.LOGIN_ERROR:
      return {
        ...state,
        isAuthenticated: false,
        isFailure: true,
        isLoading: false,
        message: action.payload,
      };
    case types.LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        isFailure: false,
        isLoading: false,
        currentUser: null,
        roles: null,
      };
    case types.LOGOUT_REQUEST:
      return {
        ...state,
        isAuthenticated: false,
        isSessionExpired: true,
      };
    case types.AUTH_REQUEST_PROFILE: //request current user profile
      return {
        ...state,
        isFailure: false,
        isLoading: true,
        currentUser: null,
      };
    case types.AUTH_PROFILE: //receive current user profile and put into state
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        isFailure: false,
        currentUser: action.payload
      };
    default:
      return state
  }
}


const authReducer = combineReducers({
  auth: login,
});

export default authReducer;
