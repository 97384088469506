import { combineReducers } from "redux";
import types from "./types";

const initialState = {
  isFetching: false,
  isFetchSuccess: false,
  isFetchError: false,
  data: [],
};

export function profiles(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_PROFILES_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.FETCH_PROFILES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isFetchSuccess: true,
        isFetchError: false,
        data: action.payload,
      };
    case types.FETCH_PROFILES_ERROR:
      return {
        ...state,
        isFetching: false,
        isFetchSuccess: false,
        isFetchError: true,
      };
    default:
      return state;
  }
}

const profileReducer = combineReducers({
  profiles: profiles,
});

export default profileReducer;
