import { combineReducers } from "redux";
import types from "./types";

const initialState = {
  isDeleting: false,
  isDeleteSuccess: false,
  isDeleteError: false,
  isAdding: false,
  isAddSuccess: false,
  isAddError: false,
  isUpdating: false,
  isUpdateSuccess: false,
  isUpdateError: false,
  isFetching: false,
  isFetchSuccess: false,
  isFetchError: false,
  centersData: [],
  centerData: [],
};

export function center(state = initialState, action) {
  switch (action.type) {
    case types.ADD_CENTER_REQUEST:
      return {
        ...state,
        isAdding: true,
      };
    case types.ADD_CENTER_SUCCESS:
      return {
        ...state,
        isAdding: false,
        isAddSuccess: true,
        isAddError: false,
      };
    case types.ADD_CENTER_ERROR:
      return {
        ...state,
        isAdding: false,
        isAddSuccess: false,
        isAddError: true,
      };
    case types.UPDATE_CENTER_REQUEST:
      return {
        ...state,
        isUpdating: true,
      };
    case types.UPDATE_CENTER_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        isUpdateSuccess: true,
        isUpdateError: false,
      };
    case types.UPDATE_CENTER_ERROR:
      return {
        ...state,
        isUpdating: false,
        isUpdateSuccess: false,
        isUpdateError: true,
      };
    case types.FETCH_CENTERS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.FETCH_CENTERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isFetchSuccess: true,
        isFetchError: false,
        centersData: action.payload,
      };
    case types.FETCH_CENTERS_ERROR:
      return {
        ...state,
        isFetching: false,
        isFetchSuccess: false,
        isFetchError: true,
        centersData: [],
      };
    case types.FETCH_CENTER_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.FETCH_CENTER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isFetchSuccess: true,
        isFetchError: false,
        centerData: action.payload,
      };
    case types.FETCH_CENTER_ERROR:
      return {
        ...state,
        isFetching: false,
        isFetchSuccess: false,
        isFetchError: true,
      };
    case types.DELETE_CENTER_REQUEST:
      return {
        ...state,
        isDeleting: true,
      };
    case types.DELETE_CENTER_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        isDeleteSuccess: true,
        isDeleteError: false,
      };
    case types.DELETE_CENTER_ERROR:
      return {
        ...state,
        isDeleting: false,
        isDeleteSuccess: false,
        isDeleteError: true,
      };
    case types.DELETE_CENTER_ERROR_RESET:
      return {
        ...state,
        isDeleteError: false,
        isDeleting: false,
        isDeleteSuccess: false
      };
    case types.RESET:
      return {
        ...state,
        isDeleting: false,
        isDeleteSuccess: false,
        isDeleteError: false,
        isAdding: false,
        isAddSuccess: false,
        isAddError: false,
        isUpdating: false,
        isUpdateSuccess: false,
        isUpdateError: false,
        centerData: [],
        centersData: [],
      };
    default:
      return state;
  }
}

const centerReducer = combineReducers({
  center: center,
});

export default centerReducer;
