import types from "./types";

const initialState = {
  isFetching: false,
  isFetchSuccess: false,
  reportData: [],
};

export function season(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_REPORT_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.FETCH_REPORT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isFetchSuccess: true,
        reportData: action.payload,
      };
    case types.FETCH_REPORT_ERROR:
      return {
        ...state,
        isFetching: false,
        isFetchSuccess: false,
      };

    case types.RESET:
      return {
        ...state,
        isFetching: false,
        isFetchSuccess: false,
        reportData: [],
      };
    default:
      return state;
  }
}

export default season;
