import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { authActions } from "../../../store/modules/auth";

/**
 * Private route to navigate over private routes
 * If not logged in - goes to login
 * If not admin but required - throws an error!
 */

class PrivateRoute extends Component {

  componentDidMount() {
    this.props.verifyToken()
  }

  render() {
    const {
      isAuthenticated,
      component: Component,
      currentUser,
      ...props
    } = this.props;

    if (isAuthenticated && !currentUser) {
      return null
    }
    return (
      <Route
        {...props}
        render={props =>
          isAuthenticated
            ?
            <main>
              <Component {...props} />
            </main>
            :
            (
              <Redirect
                to={{
                  pathname: '/login',
                  state: { from: props.location },
                }}
              />
            )
        }
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.authState.auth.isAuthenticated,
    currentUser: state.authState.auth.currentUser,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      verifyToken: authActions.verifyToken,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
