import { combineReducers } from "redux";
import types from "./types";

const initialState = {
  isDeleting: false,
  isDeleteSuccess: false,
  isDeleteError: false,
  isAdding: false,
  isAddSuccess: false,
  isAddError: false,
  isUpdating: false,
  isUpdateSuccess: false,
  isUpdateError: false,
  isFetching: false,
  isFetchSuccess: false,
  isFetchError: false,
  reservationsData: [],
  reservationData: null,
  message: null,
};

export function reservation(state = initialState, action) {
  switch (action.type) {
    case types.ADD_RESERVATION_REQUEST:
      return {
        ...state,
        isAdding: true,
      };
    case types.ADD_RESERVATION_SUCCESS:
      return {
        ...state,
        isAdding: false,
        isAddSuccess: true,
        isAddError: false,
        message: action.payload,
      };
    case types.ADD_RESERVATION_ERROR:
      return {
        ...state,
        isAdding: false,
        isAddSuccess: false,
        isAddError: true,
        message: action.payload,
      };
    case types.UPDATE_RESERVATION_REQUEST:
      return {
        ...state,
        isUpdating: true,
        message: null,
      };
    case types.UPDATE_RESERVATION_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        isUpdateSuccess: true,
        isUpdateError: false,
        message: action.payload,
      };
    case types.UPDATE_RESERVATION_ERROR:
      return {
        ...state,
        isUpdating: false,
        isUpdateSuccess: false,
        isUpdateError: true,
        message: action.payload,
      };

    case types.FETCH_RESERVATIONS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.FETCH_RESERVATIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isFetchSuccess: true,
        isFetchError: false,
        reservationsData: action.payload,
      };
    case types.FETCH_RESERVATIONS_ERROR:
      return {
        ...state,
        isFetching: false,
        isFetchSuccess: false,
        isFetchError: true,
        reservationsData: [],
      };
      case types.FETCH_RESERVATION_COLLEGIATE_REQUEST:
        return {
          ...state,
          isFetching: true,
        };
      case types.FETCH_RESERVATION_COLLEGIATE_SUCCESS:
        return {
          ...state,
          isFetching: false,
          isFetchSuccess: true,
          isFetchError: false,
          reservationsData: action.payload,
        };
      case types.FETCH_RESERVATION_COLLEGIATE_ERROR:
        return {
          ...state,
          isFetching: false,
          isFetchSuccess: false,
          isFetchError: true,
          reservationsData: [],
        };
    case types.FETCH_RESERVATION_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.FETCH_RESERVATION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isFetchSuccess: true,
        isFetchError: false,
        reservationData: action.payload,
      };
    case types.FETCH_RESERVATION_ERROR:
      return {
        ...state,
        isFetching: false,
        isFetchSuccess: false,
        isFetchError: true,
      };
    case types.DELETE_RESERVATION_REQUEST:
      return {
        ...state,
        isDeleting: true,
      };
    case types.DELETE_RESERVATION_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        isDeleteSuccess: true,
        isDeleteError: false,
        message: action.payload,

      };
    case types.DELETE_RESERVATION_ERROR:
      return {
        ...state,
        isDeleting: false,
        isDeleteSuccess: false,
        isDeleteError: true,
        message: action.payload,

      };
    case types.RESET:
      return {
        ...state,
        isDeleting: false,
        isDeleteSuccess: false,
        isDeleteError: false,
        isAdding: false,
        isAddSuccess: false,
        isAddError: false,
        isUpdating: false,
        isUpdateSuccess: false,
        isUpdateError: false,
        reservationData: null,
        reservationsData: [],
      };
      case types.RESET_SINGLE:
      return {
        reservationData: null,
      };
    default:
      return state;
  }
}

const reservationReducer = combineReducers({
  reservation: reservation,
});

export default reservationReducer;
