const FETCH_REQUESTS_REQUEST = "FETCH_REQUESTS_REQUEST";
const FETCH_REQUESTS_SUCCESS = "FETCH_REQUESTS_SUCCESS";
const FETCH_REQUESTS_ERROR = "FETCH_REQUESTS_ERROR";

const UPDATE_REQUEST_REQUEST = "UPDATE_REQUEST_REQUEST";
const UPDATE_REQUEST_SUCCESS = "UPDATE_REQUEST_SUCCESS";
const UPDATE_REQUEST_ERROR = "UPDATE_REQUEST_ERROR";

const RESET = "RESET";

export default {
  FETCH_REQUESTS_REQUEST,
  FETCH_REQUESTS_SUCCESS,
  FETCH_REQUESTS_ERROR,
  UPDATE_REQUEST_REQUEST,
  UPDATE_REQUEST_SUCCESS,
  UPDATE_REQUEST_ERROR,
  RESET,
};
