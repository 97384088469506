import { combineReducers } from "redux";
import types from "./types";

const initialState = {
  isUpdating: false,
  isUpdateSuccess: false,
  isUpdateError: false,
  isFetching: false,
  isFetchSuccess: false,
  isFetchError: false,
  isFetchingSingle: false,
  isFetchSingleSuccess: false,
  isFetchSingleError: false,
  parametersData: [],
  parameterData: [],
};

export function parameter(state = initialState, action) {
  switch (action.type) {
    case types.UPDATE_PARAMETER_REQUEST:
      return {
        ...state,
        isUpdating: true,
      };
    case types.UPDATE_PARAMETER_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        isUpdateSuccess: true,
        isUpdateError: false,
      };
    case types.UPDATE_PARAMETER_ERROR:
      return {
        ...state,
        isUpdating: false,
        isUpdateSuccess: false,
        isUpdateError: true,
      };
    case types.FETCH_PARAMETERS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.FETCH_PARAMETERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isFetchSuccess: true,
        isFetchError: false,
        parametersData: action.payload,
      };
    case types.FETCH_PARAMETERS_ERROR:
      return {
        ...state,
        isFetching: false,
        isFetchSuccess: false,
        isFetchError: true,
        parametersData: [],
      };
    case types.FETCH_PARAMETER_REQUEST:
      return {
        ...state,
        isFetchingSingle: true,
      };
    case types.FETCH_PARAMETER_SUCCESS:
      return {
        ...state,
        isFetchingSingle: false,
        isFetchSingleSuccess: true,
        isFetchSingleError: false,
        parameterData: action.payload,
      };
    case types.FETCH_PARAMETER_ERROR:
      return {
        ...state,
        isFetchingSingle: false,
        isFetchSingleSuccess: false,
        isFetchSingleError: true,
      };
    case types.RESET:
      return {
        ...state,
        isUpdating: false,
        isUpdateSuccess: false,
        isUpdateError: false,
        parameterData: [],
        parametersData: [],
      };
      case types.RESET_SINGLE_PARAMETER:
        return {
          ...state,
          parameterData: [],
        }; 
    default:
      return state;
  }
}

const parameterReducer = combineReducers({
  parameter: parameter,
});

export default parameterReducer;
