const FETCH_REPORT_REQUEST = "FETCH_REPORT_REQUEST";
const FETCH_REPORT_SUCCESS = "FETCH_REPORT_SUCCESS";
const FETCH_REPORT_ERROR = "FETCH_REPORT_ERROR";

const RESET = "RESET";

export default {
  FETCH_REPORT_REQUEST,
  FETCH_REPORT_SUCCESS,
  FETCH_REPORT_ERROR,
  RESET,
};
