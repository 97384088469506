import fetch from 'node-fetch';
import { } from 'dotenv/config';

/**
 *
 * Small service for calling GraphQL API server
 */
class ApiService {

  /**
   * define base url and field schemas here
   * @returns {ApiService}
   */
  constructor() {
    this.apiUrl = '';
  }

 

  /**
   * Generic function to fetch data from server via graphql API
   * @returns {unresolved}
   * @param query
   * @param variables
   */

  /**
   * Generic API call (for non-graphql endpoints)
   * @param {string} url
   * @param {object} params
   * @param {string} token
   * @param {string} method
   */
  async apiCall(url, params = {}, token = false, method = 'POST') {
    const apiBody = method === 'POST' || method === 'PUT' ? {
      method,
      mode: 'cors',
      headers: this.buildHeaders(token),
      body: JSON.stringify(params)
    } : {
      method,
      mode: 'cors',

      headers: this.buildHeaders(token),
    }

    const res = await fetch(`${process.env.REACT_APP_COLYPRO_PROJECT_URL}api/v1/${url}`, apiBody)
      .catch(function(error) {
        console.log(error);
        throw new Error('Ocurrió un error de comunicación, intente la acción nuevamente');
      });

    if (!res.ok) {
      if(res.status == 409){ //Conflicto en la inserción de los registros
        throw new Error("Se detectó un posible conflicto al realizar la operación, por favor intente nuevamente.");
      }
      const error = await res.json();
      throw new Error(error.message || 'Ocurrió un error de comunicación, intente la acción nuevamente')
    }

    return res.json()
  }

 

  /**
   * Build  http headers object
   * @param {string|boolean} token
   */
  buildHeaders(token = false) {
    let headers = new Headers();
    headers.append('Content-type', 'application/json');
    headers.append('Accept', 'application/json');
    if (token) {
      headers.append('Authorization', `JWT ${token}`);
    }

    return headers;
  }

  /**
   * Login user and return jwt token or throw error in case of fail
   * @param params
   */
  async login(params) {
    const res = await this.apiCall('auth/login', params);
    return res.token
  }


  /**
   * Verify current token and return current user or throw error
   * @param {string} token
   */
  async verifyToken(token) {
    const res = await this.apiCall('auth/verifyToken', {}, token);
    return res.user
  }

}

export default new ApiService();
