import { combineReducers } from "redux";
import types from "./types";

const initialState = {
  isDeleting: false,
  isDeleteSuccess: false,
  isDeleteError: false,
  isAdding: false,
  isAddSuccess: false,
  isAddError: false,
  isUpdating: false,
  isUpdateSuccess: false,
  isUpdateError: false,
  isFetching: false,
  isFetchSuccess: false,
  isFetchError: false,
  rolesData: [],
  roleData: [],
};

export function role(state = initialState, action) {
  switch (action.type) {
    case types.ADD_ROLE_REQUEST:
      return {
        ...state,
        isAdding: true,
      };
    case types.ADD_ROLE_SUCCESS:
      return {
        ...state,
        isAdding: false,
        isAddSuccess: true,
        isAddError: false,
      };
    case types.ADD_ROLE_ERROR:
      return {
        ...state,
        isAdding: false,
        isAddSuccess: false,
        isAddError: true,
      };
    case types.UPDATE_ROLE_REQUEST:
      return {
        ...state,
        isUpdating: true,
      };
    case types.UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        isUpdateSuccess: true,
        isUpdateError: false,
      };
    case types.UPDATE_ROLE_ERROR:
      return {
        ...state,
        isUpdating: false,
        isUpdateSuccess: false,
        isUpdateError: true,
      };
    case types.FETCH_ROLES_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.FETCH_ROLES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isFetchSuccess: true,
        isFetchError: false,
        rolesData: action.payload,
      };
    case types.FETCH_ROLES_ERROR:
      return {
        ...state,
        isFetching: false,
        isFetchSuccess: false,
        isFetchError: true,
      };
    case types.FETCH_ROLE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case types.FETCH_ROLE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isFetchSuccess: true,
        isFetchError: false,
        roleData: action.payload,
      };
    case types.FETCH_ROLE_ERROR:
      return {
        ...state,
        isFetching: false,
        isFetchSuccess: false,
        isFetchError: true,
      };
    case types.DELETE_ROLE_REQUEST:
      return {
        ...state,
        isDeleting: true,
      };
    case types.DELETE_ROLE_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        isDeleteSuccess: true,
        isDeleteError: false,
      };
    case types.DELETE_ROLE_ERROR:
      return {
        ...state,
        isDeleting: false,
        isDeleteSuccess: false,
        isDeleteError: true,
      };
    case types.RESET:
      return {
        ...state,
        isDeleting: false,
        isDeleteSuccess: false,
        isDeleteError: false,
        isAdding: false,
        isAddSuccess: false,
        isAddError: false,
        isUpdating: false,
        isUpdateSuccess: false,
        isUpdateError: false,
        roleData: [],
      };
    default:
      return state;
  }
}

const rolReducer = combineReducers({
  role: role,
});

export default rolReducer;
