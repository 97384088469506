import types from "./types";
import { ApiService } from "../../../utils";


/*FETCH SINGLE REPORT*/

export const fetchReport = (requestedData) => async (dispatch) => {
  dispatch(fetchReportRequest());
  try {
    const request = await ApiService.apiCall(`Report/GetReport`, {...requestedData}, false, 'POST');
    if (request.statusCode === 200) {
      dispatch(fetchReportSuccess(request));
    } else {
      dispatch(fetchReportError(request.err));
    }
  } catch (err) {
    dispatch(fetchReportError(err));
  }
};

const fetchReportRequest = () => {
  return {
    type: types.FETCH_REPORT_REQUEST,
  };
};

const fetchReportSuccess = (data) => {
  return {
    type: types.FETCH_REPORT_SUCCESS,
    payload: data.responseObject,
  };
};

const fetchReportError = (error) => {
  return {
    type: types.FETCH_REPORT_ERROR,
    payload: error.message,
    status: error.status,
  };
};

export function reset() {
    return {
      type: types.RESET,
    };
  }



export default {
  fetchReport,
  reset,
};
