import types from "./types";

const initialState = {
  isFetching: false,
  isUpdating: false,
  isSuccess: false,
  isUpdateCompleted: false,
  requestData: [],
};

export function request(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_REQUESTS_REQUEST:
      return {
        ...state,
        isFetching: true,
        isUpdateCompleted: false
      };
    case types.FETCH_REQUESTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isSuccess: true,
        isUpdateCompleted: false,
        requestData: action.payload,
      };
    case types.FETCH_REQUESTS_ERROR:
      return {
        ...state,
        isFetching: false,
        isSuccess: false,
        isUpdateCompleted: false
      };

    case types.UPDATE_REQUEST_REQUEST:
      return {
        ...state,
        isUpdating: true,
        isUpdateCompleted: false,
        isFetching: true,
      };
    case types.UPDATE_REQUEST_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        isFetching: false,
        isSuccess: true,
        isUpdateCompleted: true
      };
    case types.UPDATE_REQUEST_ERROR:
      return {
        ...state,
        isUpdating: false,
        isSuccess: false,
      };

    case types.RESET:
      return {
        ...state,
        isFetching: false,
        isUpdating: false,
        isSuccess: false,
        isUpdateCompleted: false
      };
    default:
      return state;
  }
}

export default request;
