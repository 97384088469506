const ADD_CABIN_REQUEST = "ADD_CABIN_REQUEST";
const ADD_CABIN_SUCCESS = "ADD_CABIN_SUCCESS";
const ADD_CABIN_ERROR = "ADD_CABIN_ERROR";

const DELETE_CABIN_REQUEST = "DELETE_CABIN_REQUEST";
const DELETE_CABIN_SUCCESS = "DELETE_CABIN_SUCCESS";
const DELETE_CABIN_ERROR = "DELETE_CABIN_ERROR";
const DELETE_CABIN_ERROR_RESET = "DELETE_CABIN_ERROR_RESET";

const FETCH_CABINS_REQUEST = "FETCH_CABINS_REQUEST";
const FETCH_CABINS_SUCCESS = "FETCH_CABINS_SUCCESS";
const FETCH_CABINS_ERROR = "FETCH_CABINS_ERROR";

const FETCH_CABIN_REQUEST = "FETCH_CABIN_REQUEST";
const FETCH_CABIN_SUCCESS = "FETCH_CABIN_SUCCESS";
const FETCH_CABIN_ERROR = "FETCH_CABIN_ERROR";

const UPDATE_CABIN_REQUEST = "UPDATE_CABIN_REQUEST";
const UPDATE_CABIN_SUCCESS = "UPDATE_CABIN_SUCCESS";
const UPDATE_CABIN_ERROR = "UPDATE_CABIN_ERROR";

const RESET = "RESET";
const RESET_SINGLE_CABIN = "RESET_SINGLE_CABIN";


export default {
  ADD_CABIN_REQUEST,
  ADD_CABIN_SUCCESS,
  ADD_CABIN_ERROR,
  DELETE_CABIN_REQUEST,
  DELETE_CABIN_SUCCESS,
  DELETE_CABIN_ERROR,
  DELETE_CABIN_ERROR_RESET,
  FETCH_CABINS_REQUEST,
  FETCH_CABINS_SUCCESS,
  FETCH_CABINS_ERROR,
  UPDATE_CABIN_REQUEST,
  UPDATE_CABIN_SUCCESS,
  UPDATE_CABIN_ERROR,
  FETCH_CABIN_REQUEST,
  FETCH_CABIN_SUCCESS,
  FETCH_CABIN_ERROR,
  RESET,
  RESET_SINGLE_CABIN,
};
