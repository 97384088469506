import types from "./types";
import { ApiService } from "../../../utils";

const PROFILES_PATH = "profile";

/* FETCH PROFILES*/

export const fetchProfiles = () => async (dispatch) => {
  dispatch(fetchProfilesRequest());
  try {
    const request = await ApiService.apiCall(
      `${PROFILES_PATH}/GetAllProfiles?pageNumber=1&pageSize=100`,
      {},
      false,
      "GET"
    );
    if (request.statusCode === 200) {
      dispatch(fetchProfilesSuccess(request));
    } else {
      dispatch(fetchProfilesError(request.err));
    }
  } catch (err) {
    dispatch(fetchProfilesError(err));
  }
};

const fetchProfilesRequest = () => {
  return {
    type: types.FETCH_PROFILES_REQUEST,
  };
};

const fetchProfilesSuccess = (data) => {
  return {
    type: types.FETCH_PROFILES_SUCCESS,
    payload: data.responseObject,
  };
};

const fetchProfilesError = (error) => {

  return {
    type: types.FETCH_PROFILES_ERROR,
    payload: error.message,
    status: error.status,
  };
};


export default {
  fetchProfiles,
};
